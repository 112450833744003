import { useEffect, useState } from "react"
import { SlackErrorHandle, ToastError } from "../components/tools/index"
import { useDispatch, useSelector } from 'react-redux'
import { addOrderData, resetOrderData, addProduct, addCategoryMup, removeCategoryMup } from "../store/orderData/OrderDataSlices"
import { addClientInfo } from "../store/clientInfo/ClientInfoSlices"



export const useOrderForm = (setSaveOrder) => {

    /* REDUX */
    const dispatch = useDispatch()
    // datos de usuario
    const userData = useSelector((state) => state.user)
    // formulario de pedido
    const formState = useSelector((state) => state.orderData)
    // lista de precios
    const precios = useSelector(state => state.price)
    // lista de clientes
    const Clientes = useSelector(state => state.clients)
    //lista de gerencias
    const gerenciasIds = useSelector(state => state.gerencias)
    //condiciones de pago
    const condicionesDePago = useSelector(state => state.condicionesDePago)
        //console.log(condicionesDePago)
    //Verificar la orden
    const orderInfo = useSelector((state) => state.orderData)
    
    /* VALIDACIONES */
    // estado con la validacion del cliente cargado
    const [clienteOk, setClienteOk] = useState(false)
    // validacion de productos cargados correctamente 
    const [productoOk, setProductoOk] = useState(false)
    // validacion de prod repetido
    const [repeatedOk, setRepeatedOk] = useState(true)
    // validacion de fecha de entrega
    const [fechaPactadaOk, setFechaPactadaOk] = useState(true)
    // lista de los skus duplicados
    const [skusDuplicados, setSkusDuplicados] = useState([])
    //estado para habilitar la edicion de direcciones [0]=facturacion [1]=entrega
    const [editDir, setEditDir] = useState([false, false])
    // referencia a la lista de productos dentro del formulario
    const listaProv = formState.DocumentLines


    /******************** INPUT HANDLERS ***********************/
    //cambios en el nivel general del pedido
    const onInputChange = ({ target: { name, value, title } }, section) => {
        //console.log('test', { target: { name, value, title } })
          
        if (name.includes('Desc')) {
            dispatch(addOrderData({
                [name]: Math.max(0, value)
            }))

            // en el caso de la categoria de producto
        } else if (name === 'CategoriaMup') {
            handleCategoryCheckbox(value)

        //caso true o false switch
        // } else if (name === 'Proforma') {
        //     dispatch(addOrderData({
        //         [name]: !formState.Proforma
        //     }))
        } else {
            //caso del numero de pedido
            dispatch(addOrderData({
                [name]: value
            }))
        }

    }

    //checkbox de categoria
    const handleCategoryCheckbox = (value) => {
        if (formState.CategoriaMup.includes(value)) {
            dispatch(removeCategoryMup(value))
        } else {
            dispatch(addCategoryMup(value))
        }
    }

    const getPymentGroup = (text) => {
        //console.log(text)
        return condicionesDePago.find(({ PymntGroup }) => PymntGroup === text)
    }
    const getPymentGroupByCode = (code) => {
        console.log(code)
        return condicionesDePago.find(({ GroupNum }) => GroupNum === code)
    }
    //probado la variable
    // seleccion de cliente
    // toma el valor del buscador y luego busca el objeto del cliente
    const onClientChange = ({ target: { value } }) => {
        let clientObj = Clientes.find(c => c.CUIT === value.substring(0, value.indexOf(' ')))
        let mupProv

        if (clientObj) {

            if (clientObj.MUp) {
                mupProv = parseInt(((100 / (100 - clientObj.MUp)) - 1) * 100)
            } else {
                mupProv = ''
            }
            dispatch(addClientInfo(clientObj))
            dispatch(resetOrderData())

            dispatch(addOrderData({
                UID: userData.UID,
                CardCode: clientObj.Cliente_ID,
                Cliente: clientObj.Cliente,
                Cuit: clientObj.CUIT,
                MUPPactado: mupProv,
                Margen: clientObj.MUp ? Number(clientObj.MUp) : '',
                Direccion_Facturacion: clientObj.Direccion_Facturacion ? clientObj.Direccion_Facturacion : '',
                Provincia_Facturacion: clientObj.Provincia_Facturacion ? clientObj.Provincia_Facturacion : '',
                Telefono: clientObj.Telefono_1 ? clientObj.Telefono_1 : '',
                Direccion_Entrega: clientObj.Direccion_Entrega ? clientObj.Direccion_Entrega : '',
                Provincia_Entrega: clientObj.Provincia_Entrega ? clientObj.Provincia_Entrega : '',
                Cod__Postal_Factu: clientObj.Cod__Postal_Factu ? clientObj.Cod__Postal_Factu : '',
                SlpCode: userData.Id,
                VendedorNombre: userData.Nombre,
                VendedorApellido: userData.Apellido,
                Iva: clientObj.Iva,
                ShipToDef: clientObj.ShipToDef,
                //TODO: falta transporte en la consulta,  (id_direccion?)
                //Transporte: clientObj.Transporte
                TipoPedido: '1',
                Turno: clientObj.Turno,
                Contacto_Turno: clientObj.Contacto_Turno ? clientObj.Contacto_Turno : '',
                PymntGroup: getPymentGroup(clientObj.PymntGroup),
                DocumentLines: [{ Articulo: '', Cantidad: '', MUP: mupProv, Margen: Number(clientObj.MUp) }]
            }))

        } else {
            console.log('error en seleccion de cliente')
        }
        restoreProductInput()
    }
    
    const onPymntChange = ({ target: { value } }, section) => {
        //console.log(value , section)
        const newData = {
            PymntGroup: getPymentGroupByCode(parseInt(value))
        }
        //console.log(newData)
        dispatch(addOrderData({
            ...newData
        }))
    }
    //

    //agrega un producto con el ultimo MUP utilizado
    const addNewProduct = (mup) => {
        dispatch(addProduct(mup))
    }

    //seleccion de producto
    //toma el valor del buscador y luego busca el objeto del producto
    const onPoductChange = ({ target: { value, id, name } }, section) => {

        let productObj = precios.find(p => p.ItemCode === value.trim().substr(0, value.indexOf(' ')))

        let index = parseInt(id.substring(0, id.indexOf(name)))
        //hace una copia de document lines para ser modificada
        let doclines = formState.DocumentLines.map((el) => el)

        let mupProd = (doclines[index].MUP > 0) ? doclines[index].MUP : formState.MUPPactado
        let marg = (doclines[index].Margen > 0) ? doclines[index].Margen : formState.Margen
        //console.log(mupProd ,'test')
        let bon =  (doclines[index].Bono > 0)? doclines[index].Bono : formState.Bono
        //console.log(bon,'test')

        if (name === 'sku' && productObj) {
            doclines[index] = {
                ...doclines[index],
                Articulo: productObj.ItemCode,
                Descripcion: productObj.ItemName,
                // pvp de sap
                Pvp: productObj.Price,
                PvpSinIva:( productObj.Price / 1.21) ,
                Cantidad: '',
                Bono: '',
                descPvp:'',
                MUP: mupProd,
                Margen: marg, 
                // pvp siniva sin margen
                CostoSinIva: (productObj.Price / 1.21) / (1 + (mupProd / 100)), 
                descuentoPvpTotal: '',
            }
        }
        //actualiza el estado con la nueva lista
        dispatch(addOrderData({ DocumentLines: doclines }))

    }


    //cambios en el nivel del producto
    const onDetailInputChange = ({ target: { id, name, value } }, section) => {
        //console.log( id, name, value ) 

        //toma el indice del producto del id del input
        let index = parseInt(id.substring(0, id.indexOf(name)))

        let doclines = formState.DocumentLines.map((el) => el)
        
        if (value >= "0") {
            //console.log(value)
            let parseValue = name === "MUP"  ? parseFloat(value) : parseInt(value)
            //console.log(`test`,parseValue)
            doclines = doclines.map((el) => (
                doclines.indexOf(el) === index
                    ?
                    el = {
                        ...el,
                        [name]: parseValue,
                    }
                    : el
            ))
        }
      
        // mapea con los ultimos cambios y calcula los valores
        doclines = doclines.map((el) => (
            doclines.indexOf(el) === index
                ? el = {
                    ...el,
                        PvpConBono:((el.Pvp - el.Bono) / 1.21),
                        PvpSinIva:((el.Pvp - el.Bono) / 1.21),
                        sumPvp: el.Cantidad * el.Pvp ,
                        // este es el invento mio PvpSinIva:((el.Pvp - el.Bono) / 1.21),
                        sumPvpSinIva: (el.PvpSinIva * el.Cantidad),
                        // pvp sin iva con mup
                        CostoSinIva: (el.PvpSinIva / (1 + (el.MUP / 100))),
                        // costo sin iva * cant
                                sumCostoSinIva: (el.PvpSinIva / (1 + (el.MUP / 100))) * el.Cantidad, // NO CONTEMPLA BONO

                                // este lo tuve que hacer por que el bono se le aplica directo al pvp
                                sumDupliCostoSinIva: (((el.Pvp - el.Bono) / 1.21) /( 1 + (el.MUP/100)))* el.Cantidad,   
                                // si excento y liquida IVA el se tiene que mostrar esto
                                sumTriCostoconIva: (((el.Pvp - el.Bono) ) /( 1 + (el.MUP/100)))* el.Cantidad,   
                            // ----------------------

                        // calcula el margen a partir del mup
                        Margen: Math.abs(((100 / (1 + (el.MUP / 100))) - 100).toFixed(4)),
                        // descuentoPvpTotal:  el.descPvp * el.Cantidad,
                        BonoXcantidad: ( el.Bono * el.Cantidad), 
                    }
                    : el
                )
            )

            // console.log(doclines[0].PvpConBono)
            // console.log((1 + (doclines[0].MUP / 100)))
            // console.log(doclines[0].Cantidad)
            // console.log(doclines[0].Bono)
            // console.log(doclines[0].sumDupliCostoSinIva)


        dispatch(addOrderData({ DocumentLines: doclines }))
    }

    const onSwitchChange = (name, bool) => {
        dispatch(addOrderData({ [name]: bool }))
    }

    /************ AGREGAR PRODUCTOS *************/

    //agrega un objeto vacio para que salga otra fila de inputs
    const addProductToList = () => {

        if (addProductValidation()) {
            addNewProduct(listaProv[listaProv.length - 1].MUP)
        } else {
            console.log("fallo en la carga de pedido")
        }

    }

    //validacion para agregar otro producto (true si la cantidad y el mup son may a cero)
    const addProductValidation = () => {
        let validate = true

        listaProv.map((p) => {
            for (let i in p) {
                if (

                    (p.Cantidad === 0 && p.Articulo !== 'eliminado')
                    || (p.MUP <= 0 && p.Articulo !== 'eliminado')
                    || (p.Cantidad === "")
                ) {
                    validate = false
                }
            }
        })

        return (validate && clienteOk)
    }

    /************ ELIMINAR PRODUCTOS *************/
    // func para eliminar productos del pedido
    const deleteProduct = ({ target: { id, name } }) => {

        let index = parseInt(id.substring(0, id.indexOf(name)))
        let doclines = formState.DocumentLines.map((el) => el)
        let input = document.getElementById(`${index}id`)


        doclines = doclines.map((el) => (
            doclines.indexOf(el) === index
                ? el = { MUP: 0, Pvp: 0, Articulo: "eliminado", Cantidad: 0 , Bono: 0}
                : el
        ))

        dispatch(addOrderData({ DocumentLines: doclines }))

        //oculta el input eliminado
        input.classList.add("displayNone")
    }


    /* DIRECCIONES */
    //recibe 0 si es facturacion y 1 si es entrega, y true para activar la edicion y false para volver
    const handleEditAdress = (indx, bool, field) => {
        if (formState.Cliente) {
            let edit = [...editDir]
            edit[indx] = bool

            if (!bool) {
                dispatch(addOrderData({ [field]: '' }))
            }

            setEditDir(edit)
        }
    }

//recibe el numbre del campo a agregar y la direccion nueva
    const onAddAddress = (name, inputRef, address, section) => {
        //console.log(address.ShipToDef)
        if (address.ShipToDef === '' || !address.ShipToDef ) {
            console.log('si address.ShipToDef es un string vacío no va a pasar nada');
        } else {
            dispatch(addOrderData({ ShipToDef: address.ShipToDef }));
        }
        
        dispatch(addOrderData({ [name]: address }))
        //cierra el detalle
        document.getElementById(inputRef).removeAttribute("open")
    }


/******************** VALIDATIONS ***********************/

    //devuelve la lista de skus cargados con el indice de la lista {index: , sku:}
    const productsSku = () => {
        let skuList = []

        listaProv.map((p) => {
            if (p.Articulo !== null) {
                skuList.push({ index: listaProv.indexOf(p), sku: p.Articulo })
            }
        })
        return skuList
    }

    //setea skusduplicados con una lista de objetos con los index y productos que estan duplicados
    const productValidation = () => {
        let allSkus = productsSku()
        const countMap = new Map();

        allSkus.forEach(obj => {
            if (!countMap.has(obj.sku)) {
                countMap.set(obj.sku, 1);
            } else {
                countMap.set(obj.sku, countMap.get(obj.sku) + 1);
            }
        });

        let duplicates = allSkus.filter(obj => countMap.get(obj.sku) > 1);
        let repes = duplicates.filter(obj => obj.sku !== 'eliminado')

        setSkusDuplicados(repes)

        setRepeatedOk(repes.length === 0)
    }

    // funcion para el cambio de clase del input
    //recibe el indice del input y busca en la lista de repetidos si coincide 
    const inputRepeated = (inputIndex) => {
        return skusDuplicados.map(s => s.index).includes(inputIndex)
    }

    const clientCheck = () => {
        setClienteOk(Clientes.find(c => c.CUIT === formState.Cuit) !== undefined)
    }

    // valida que se haya cargado una fecha en casoo de no ser un pedido normal
    const validateFechaPact = () => {

        let fecha = formState.AnioSelecc+'-'+formState.MesSelecc

        // console.log(fecha)
        dispatch(addOrderData({
            MesPactado:fecha
        }))
        //console.log(formState.TipoPedido )
        if (formState.TipoPedido !== '1') {
            //console.log('test')
            if (formState.MesSelecc > 0){
                //console.log('test')
                setFechaPactadaOk(true)
            } else {
                setFechaPactadaOk(false)
            }
        } else {
            setFechaPactadaOk(true)
        }
    }

    // valida y da true si hay algo que falte por completar
    const submitValidator = () => {

        let direF = formState.Direccion_Facturacion !== '' || formState.Direccion_Facturacion_New !== undefined
        let direE = formState.Direccion_Entrega !== '' || formState.Direccion_Entrega_New !== undefined
        let pymntGroup = formState.PymntGroup !== ''
        let categMup = formState.CategoriaMup.length > 0
        //direF,

        //console.log(fechaPactadaOk)
        let validate = [clienteOk, productoOk, repeatedOk, fechaPactadaOk, direE, pymntGroup, categMup]
           // console.log('test',validate) 

        return validate.some(value => value === false)
    }
    //console.log(formState.PymntGroup)
    /*********** CALCULOS ***************/

    // clacula los valores del pedido en general
    const calculateValues = () => {
        let sumPvp = 0
        let sumPvpSIva = 0
        let sumCosto = 0
        let total = 0
        let totalConDesc = 0 
        let totalpp = 0
        let subtotsiniva = 0
        let sumTotalConIvaTripl = 0
        let iva = 0
        let sumBono = 0 
        let sumCosto1 = 0
        let sumCostoConIvaTripl = 0
        // let Cantidad = 0
        //'EX' -> iva exento
        //'RI' -> resp insc
        let ivaPrCent = (formState.Iva === 'EX' ? 0 : 0.21)



        if (listaProv.length > 0) {
          // console.log(listaProv)
            for (let i = 0; i < listaProv.length; i++) {
                
                if (listaProv[i].sumPvp) {
                    sumPvp += listaProv[i].sumPvp;
                    sumPvpSIva += listaProv[i].sumPvpSinIva;
                    sumCosto += listaProv[i].sumCostoSinIva ;
                    sumBono += listaProv[i].BonoXcantidad ;
                    sumCosto1 += listaProv[i].sumDupliCostoSinIva;
                // ---------------- a partir de aca van los que tienen iva --------------------------------
                //este seria el neto
                    sumCostoConIvaTripl += listaProv[i].sumTriCostoconIva;
                //este seria el total
                    sumTotalConIvaTripl +=  listaProv[i].sumTriCostoconIva;
                    // Cantidad = listaProv[i].Cantidad
                   
                }
            }
            //subtotsiniva = (sumCosto - (sumCosto * (formState.DescuentoTotal / 100))) .........
            let desc1 = parseFloat((sumCosto1 * (orderInfo.Descuento01 / 100)).toFixed(2)) || 0;
            let desc2 = parseFloat((sumCosto1 * (orderInfo.Descuento02 / 100)).toFixed(2)) || 0;
            let desc3 = parseFloat((sumCosto1 * (orderInfo.Descuento03 / 100)).toFixed(2)) || 0;
              
            // let cant = orderInfo.DocumentLines
            let Desc = desc1 + desc2 + desc3;
            let totalDesc = Desc 

            // -------------- Descuentos con IVA ----------------
            let desc1Iva = parseFloat((sumCostoConIvaTripl * (orderInfo.Descuento01 / 100)).toFixed(2)) || 0;
            let desc2Iva = parseFloat((sumCostoConIvaTripl * (orderInfo.Descuento02 / 100)).toFixed(2)) || 0;
            let desc3Iva = parseFloat((sumCostoConIvaTripl * (orderInfo.Descuento03 / 100)).toFixed(2)) || 0;
              
            let DescIva = desc1Iva + desc2Iva + desc3Iva;
            let totalDescIva = DescIva 
            //---------------------------------------------------

            subtotsiniva = ((sumCosto1 - totalDesc)) 
            iva =  ( sumCosto1 - totalDesc )  *  ivaPrCent
            total =  ( subtotsiniva +  iva ) 
            // console.log(sumCostoConIvaTripl)
            // la suma del total con el iva aplicado en producto 
            sumTotalConIvaTripl = ( sumTotalConIvaTripl - totalDescIva)
        }

        return (
            {
                SumPvp: sumPvp,
                SumPvpSinIva: sumPvpSIva,
                Neto: sumCosto1,
                SubTotal: subtotsiniva,
                IVA21: iva,
                Total: total,
                TotalDPP: totalpp,
                TotalBono: sumBono,
                totalConDesc:totalConDesc,
                sumCostoConIvaTripl:sumCostoConIvaTripl,
                sumTotalConIvaTripl: sumTotalConIvaTripl,
            }
        )
    }

    /******** AUXILIARES ***********/
    // funcion que vuelve visible al input si estaba eliminado y resetea el valor del producto
    const restoreProductInput = () => {
        let firstInput = document.getElementById('0id')
        let firstProduct = document.getElementById('0sku')

        if (firstInput && firstInput.classList.contains('displayNone')) {
            firstInput.classList.remove('displayNone')
        }

        //elimina el valor del input
        if (firstProduct) {
            firstProduct.value = ''
        }
    }

    //da formato a las direcciones que se toman de Direcciones_Extra
    const parseAddress = (address) => {
        let dire = ''

        if (address.Calle) { dire = address.Calle }
        if (address.Número_de_la_calle) { dire += ` ${address.Número_de_la_calle}` }
        if (address.Ciudad) { dire += ` - ${address.Ciudad}` }
        if (address.Código_postal) { dire += ` (${address.Código_postal})` }
        if (address.País) { dire += ` - ${address.País}` }

        return dire
    }

    /******* SUBMIT *******/
    const submitOrder = (event) => {

        removeDeletedProduct()

        try {
            dispatch(addOrderData({
                Fecha: new Date().toLocaleDateString(),
                Estado: event.target.name,
                Comments: formState.NumPedidoDelCliente
                    ? 'N° Pedido: ' + formState.NumPedidoDelCliente + ', ' + formState.Comments
                    : formState.Comments
            }))

            // limpia el formulario
            document.getElementById('ordFormRef').reset()

            setSaveOrder(true)

        } catch (error) {
            console.log(error)
            ToastError("fallo en la carga del pedido")
            SlackErrorHandle(`${new Date()}: -> Fallo en la carga del pedido \n-> *${error}* \n-> User: ${userData.email}`)
        }
    }

    //usar formstate.ID para ver si ya esta subido
    const onSubmitOrderForm = async (event, section) => {

        removeDeletedProduct()

        submitOrder(event)
    }

    const removeDeletedProduct = () => {
        let productos = listaProv.filter(pro => pro.Articulo !== "eliminado")

        dispatch(addOrderData({
            DocumentLines: productos
        }))
    }


    /******* EFECTOS *******/
    useEffect(() => {
        // filtra la lista de productos sacando los eliminados
        let list = listaProv.filter(prod => prod.Articulo !== 'eliminado')
        // revisa que todos los productos esten completos
        let complete = !list.map(prod => (
            prod.Articulo !== null && prod.Cantidad > 0 && prod.MUP > 0
        )).includes(false)

        // todos los productos estan completos
        setProductoOk(complete && list.length > 0)

        productValidation()

        clientCheck()

    }, [formState])

    useEffect(() => {
        validateFechaPact()
        //console.log('test')
    }, [formState.MesSelecc, formState.AnioSelecc, formState.TipoPedido])


    useEffect(() => {
        dispatch(addOrderData(calculateValues()))
    }, [listaProv, formState.DescuentoTotal])

    // suma los descuentos cuando se modifica  desc 2(DC) + desc 3(DF)
    useEffect(() => {
        let desc02 = formState.Descuento02 ? formState.Descuento02 : 0
        let desc03 = formState.Descuento03 ? formState.Descuento03 : 0
        let desc01 = formState.Descuento01 ? formState.Descuento01 : 0

        dispatch(addOrderData(
            {
                DescuentoTotal: parseInt(desc03) + parseInt(desc02) + parseInt(desc01)
            }
        ))
    }, [formState.Descuento03, formState.Descuento02, formState.Descuento01])


    //console.log(formState)

    return {
        ...formState,
        formState,
        onInputChange,
        onClientChange,
        onPoductChange,
        onDetailInputChange,
        addProductToList,
        deleteProduct,
        addProductValidation,
        clienteOk,
        productoOk,
        repeatedOk,
        fechaPactadaOk,
        onSubmitOrderForm,
        inputRepeated,
        onSwitchChange,
        handleEditAdress,
        editDir,
        onAddAddress,
        handleCategoryCheckbox,
        parseAddress,
        submitValidator,
        onPymntChange
    }
}
