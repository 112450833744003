import { SlackErrorHandle, ToastError } from '../components/tools';

//Conect se loguea al service layer y almacena las cookies en el browser
export const Conect = async (CompanyDB, UserName, Password, url, ServiceLayer, checkSapTest) => {

    try {
        var raw = JSON.stringify({
            "CompanyDB": CompanyDB,
            "UserName": UserName,
            "Password": Password
        });

        checkSapTest(CompanyDB)

        // const response = await ServiceLayer(url).post('/Login', raw)
        const response = await Promise.race([
            ServiceLayer(url).post('/Login', raw),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('error al conectar con sap')), 15000)
            ),
        ])

        if (response.status === 200) {

            console.log(`Conectado con Service Layer ${response.status}`)
            return response.status

        } else {
            console.log(`sin conexion ${response.status}`)
            SlackErrorHandle(`${new Date()}: -> Fallo en la conexion a sap `)
            ///return response.data.error.message.value
            return response.status
        }
    } catch (error) {
        console.log('error en la conexion con sap', error.message)

    }
};

//Aunque la instancia de conexion se rompe a los 10 minutos, tambien creamos un logueo para cerrar la sesion en el servidor
export const SLlogout = async (url, ServiceLayer) => {

    const logouturl = "/Logout"

    const response = await ServiceLayer(url).post(logouturl)
    if (response.status === 204) {
        console.log("Se ha desconectado del Service Layer")
    }
};

export const PostOrders = async (url, ServiceLayer, data) => {
   // console.log( data )
    const porders = "/Quotations"
    
    
   
    let DocumentLines = []
  
    data.DocumentLines.map((doc) => {
        let items = {}
        let pvpAplicado = 0

       

        if(doc.Bono > 0){

            pvpAplicado = doc.PvpConBono        
             console.log('test1', doc.PvpConBono)
        }else{
            pvpAplicado = doc.PvpSinIva
             console.log('test2', doc.PvpSinIva)
         
        }
      
      
        return (
            items.DiscountPercent = doc.Margen,
            items.ItemCode = doc.Articulo,
            items.Quantity = doc.Cantidad,
            items.UnitPrice = pvpAplicado,
            items.WarehouseCode = "20", // Harcodeamos el deposito
            DocumentLines.push(items))
    })

    var raw = JSON.stringify({
        "U_RBI_CalculaMUP": "Si",
        "U_DC": data.Descuento02,
        "U_DF": data.Descuento03,
        "U_DPP": data.Descuento01,
        "CardCode": data.CardCode,
        "CardName": data.Cliente,
        "DocDueDate": new Date().toISOString().split("T")[0],
        "DocumentLines": DocumentLines,
        "U_PV_ID": data.Num_pedido,
        "U_PV_U": data.UID,
        "U_PV_OC": data.NumPedidoDelCliente,
        "Comments": data.Comments,
        "U_TipoPedido": parseInt(data.TipoPedido),
        "U_Turno": data.Turno,
        "U_Contacto_turno": data.Contacto_Turno,
        "U_CantidadEntregas": data.CantEntregas || '1',
        "U_MesPactado": data.MesSelecc + '-01'  ,
        //"U_MesPactado": 0 + '-' + 0 + '-01'  || '0',
        "PaymentGroupCode": data.PymntGroup.GroupNum || data.PymntGroup.Code,
        "U_P007": data.Proforma.P007,
        "U_P013": data.Proforma.P013,
        "U_PC01": data.Proforma.PC01,
        "U_PC02": data.Proforma.PC02,
        "U_P016": data.Proforma.P016,
        //"U_PC05": data.Proforma.PC05,
        //"U_PC06": data.Proforma.PC06,
        //"U_PC07": data.Proforma.PC07,
        //"U_PC08": data.Proforma.PC08,


        ...(
            data.Direccion_Entrega_New
                ? {
                    "ShipToCode": data.Direccion_Entrega_New.ShipToDef !== undefined 
                                    ? data.Direccion_Entrega_New.ShipToDef 
                                    : data.ShipToDef,
                    "Address2": `${data.Direccion_Entrega_New.ShipToStreet} ${data.Direccion_Entrega_New.ShipToStreetNo}
                    ${data.Direccion_Entrega_New.ShipToCity} ${data.Direccion_Entrega_New.ShipToCountry}
                    tte: ${data.Transporte}`,
                }
                : {}
        ),
    });
    
    try {

        const response = await ServiceLayer(url).post(porders, raw)
        //console.log('test')

        return (response)
    } catch (error) {
        console.log(error)
        ToastError('Fallo en la subida a sap')
        SlackErrorHandle(`${new Date()}: -> Fallo en la subida a sap -> ${error} -> User: ${data.VendedorApellido}`)
        console.log(error.response.data.error.message.value)
        return (error.response.data.error.message.value)
    }
}
