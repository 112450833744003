import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from 'react-google-autocomplete';
import { AddOrder, Adddata, db, updateRevision } from '../../data/index'
import { useOrderForm } from "../../hooks/index"
import { SlackErrorHandle } from '../tools/index'
import homeIco from "../../assets/images/homeIco.png"
import { doc,getDoc } from "firebase/firestore";
import { useGeneral } from "../../context";
import { TextSwitch } from "../ui/switch";
import searchIco from '../../assets/images/searchIco.png'

export const OrderForm = ({ section, closeSection }) => {

    const { pedidosCollectionFirebase, tiposPedidos, formatDate,
        mensajesCollectionFirebase, renameKey } = useGeneral()

    const inputEntrega = useRef(null)
    const lastInput = useRef(null)

    const [saving, setSaving] = useState(false)

    const userData = useSelector(state => state.user)
    const precios = useSelector(state => state.price)
    const clientes = useSelector(state => state.clients)
    const clientInfo = useSelector(state => state.clientInfo)
    const orderInfo = useSelector((state) => state.orderData)
    const condicionesDePago = useSelector((state) => state.condicionesDePago)

    //console.log(clientInfo)
    //indicador para la carga de dire con places
    const [addressError, setAddressError] = useState({ detailsFacturacion: false, detailsEntrega: false })

    //indicador para que guarde todo
    const [saveOrder, setSaveOrder] = useState(false)

    const meses = ['', 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
    //tipo de pedido
    // const tiposPedidos = ['Normal', 'Programa', 'Preventa', 'Factura Anticipo']

    const dispatch = useDispatch()

    // estructura del formulario (pedido)
    const {
        onInputChange, onClientChange, onPoductChange, onDetailInputChange,
        addProductToList, deleteProduct, addProductValidation, clienteOk,
        productoOk, repeatedOk, onSubmitOrderForm, inputRepeated, onSwitchChange,
        handleEditAdress, editDir, onAddAddress, handleCategoryCheckbox, fechaPactadaOk,
        parseAddress, submitValidator, onPymntChange
    } = useOrderForm(setSaveOrder)

    //funcion para formatear a pesos arg (formatter.format(num))
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    //genera las keys nuevas a partir del inputref (detailsFacturacion, detailsEntrega)
    const generateKeyMapping = (inputRef) => {
        if (inputRef === 'detailsFacturacion') {
            // objetos con la keyVieja : keyNueva
            return {
                route: 'BillToStreet',
                street_number: 'BillToStreetNo',
                administrative_area_level_1: 'BillToCity',
                locality: 'BillToState',
                country: 'BillToCountry',
            }
        } else {
            return {
                route: 'ShipToStreet',
                street_number: 'ShipToStreetNo',
                administrative_area_level_1: 'ShipToCity',
                locality: 'ShipToState',
                country: 'ShipToCountry',
            }
        }
    }

    const [stateSection,setStateSection]= useState(false)
    //console.log(stateSection)

    const handleSubmitOrder = async (e) => {
        e.preventDefault()
        try {
            setSaving(true)
            if (section === 'MODIFICAR') {
               // console.log(pedidosCollectionFirebase, orderInfo.ID);
                const docRef = doc(db, pedidosCollectionFirebase, orderInfo.ID);
                const docSnap = await getDoc(docRef);
            
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    if ('DocNum' in data) {
                        //console.log('Existe');
                        alert('Este Pedido esta en cola a sap, espera unos minutos')
                        closeSection()
                        window.location.reload();
                        setSaving(false)
                        return
                    } else {
                        //console.log('No existe');
                        await updateRevision(pedidosCollectionFirebase, orderInfo.ID, userData.Apellido, 'Modificada')
                        await sendStateNotification(section)
                    }
                } else {
                    console.log('No existe el documento');
                }
            }
            await onSubmitOrderForm(e, section)
        } catch (error) {
            setSaving(false)
            console.log(error)
        }
    }

    //TODO: Revisar
    const sendStateNotification = async (section) => {
        setStateSection(false)
        //console.log('test',section)
        const docRef = doc(db, mensajesCollectionFirebase, orderInfo.UID)
        const docSnap = await getDoc(docRef)

        //numero de mensaje a agregar
        let msjNumB = docSnap.data() ? docSnap.data()[orderInfo.Num_pedido] : 0
        let msjNum = msjNumB ? Object.keys(msjNumB.Mensaje).length : 0

        let newMsg = {
            [orderInfo.Num_pedido]: {
                Vendedor: orderInfo.VendedorApellido,
                UID: orderInfo.UID,
                ...(msjNum === 0 ? { FechaDeCreacion: new Date(), } : {}),
                Cliente: orderInfo.Cliente,
                MontoTotal: orderInfo.Total,
                FechaPedido: orderInfo.Fecha,
                NuevoMensaje: true,
                UltimoMensajeFecha: new Date(),
                UltimoMensajeDe: userData.Nombre + " " + userData.Apellido,
                Mensaje: {
                    [msjNum]: {
                        Index: msjNum,
                        Leido: false,
                        Asunto: "Orden Modificada",
                        //Contenido: message,
                        Fecha: new Date(),
                        User: userData.Nombre + " " + userData.Apellido,
                    }
                }
            }
        }
        try {
            if(section === 'MODIFICAR'){
                const refDoc = doc(db,pedidosCollectionFirebase,orderInfo.ID)
                const spanDoc = await getDoc(refDoc)
               if(!spanDoc.data().DocNum){
                    await Adddata(docRef, newMsg)
                    //console.log(`aca actualiza`)
                }else{   
                    //console.log('existe el documento')
                    setStateSection(true)
               }
            }else{
                //console.log(`el else`)
                await Adddata(docRef, newMsg)
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const removeErrorInput = () => {
        if (inputEntrega.current.classList.contains('clrBorderRed')) {
            inputEntrega.current.classList.remove('clrBorderRed')
        }
    }
    const setErrorInput = () => {
        if (!inputEntrega.current.classList.contains('clrBorderRed')) {
            inputEntrega.current.classList.add('clrBorderRed')
        }
    }

    //toma el nombre del campo, la referencia al div, y un objeto del autoselect
    const handleSelectPlace = (name, inputRef, addressComp) => {

        if (addressComp) {
            const keyMappings = generateKeyMapping(inputRef)

            let address = {}
            let newAddress = {}

            //arma un obj con las partes separadas
            addressComp.forEach(c => {
                address[c.types[0]] = c.long_name
            })

            for (const oldkey in address) {
                if (keyMappings[oldkey]) {
                    let newkey = keyMappings[oldkey]
                    newAddress[newkey] = address[oldkey]
                }
            }

            if (newAddress.ShipToStreetNo) {
                onAddAddress(name, inputRef, newAddress, section)
            } else {
                console.log('falta numero de calle')
                setErrorInput()
            }
        } else {
            console.log('direccion no existe')
        }
    }



    //---------------------------------------------------------------------------------------------------------------------------
    //-------------------------------------- testear para poner el TriCostoconIva -----------------------------------------------
    //---------------------------------------------------------------------------------------------------------------------------

    // console.log(orderInfo.ShipToDef)
    // console.log(orderInfo.DocumentLines)
    // console.log(clientInfo.liquidaIva)
    // console.log(clientInfo.Iva)


    //---------------------------------------------------------------------------------------------------------------------------
    //--------------------- Esto esta aca por que confunde el que no se actualice el Bono a tiempo ------------------------------
    //---------------------------------------------------------------------------------------------------------------------------

    const ContarCostoCorrectTemporal = (pvp,bono,mup,costNorm) => {
        const markup = (1+(mup/100));
        const result = parseInt(((pvp - bono) / 1.21 )/ markup) ;
        // console.log(pvp)
        // console.log(bono)
        // console.log(costNorm)
        const normFormat = formatter.format(costNorm)
       // console.log(result)
       if (bono === ''){
            return normFormat || 0
       }else{
            return result || 0
       }
    }
    // falta agregarlo a export order

    //-------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------
    //-------------------------------------------------------------------------------------------

    // cierra y guarda el pedido con el submit del form 
    useEffect(() => {
        if (saveOrder) {
            setSaveOrder(false)
                if (!stateSection) {
                    console.log('cuando no existe docnum')
                        try {
                            console.log('aca actualiza')
                            orderInfo.ID
                                ? Adddata(doc(db, pedidosCollectionFirebase, orderInfo.ID), orderInfo)
                                : AddOrder(orderInfo, pedidosCollectionFirebase)
                        } catch (error) {
                            console.log(error)
                            SlackErrorHandle(
                                `${new Date()}: -> Error en creacion de pedido -> ${error} -> Usuario: ${userData.Apellido}`
                            )
                        } finally {
                            closeSection()
                        }
                }else {
                        // Si no se cumple la condición adicional, hacer algo más
                        console.log("no se cumple la condición adicional")
                }
        }
    }, [saveOrder])

    // cancela el enter como submit
    const checkKeyDown = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    useEffect(() => {
        if (lastInput.current) {
            lastInput.current.focus()
        }
    }, [orderInfo.DocumentLines.length])



    // const onChangeDataTime = ({ target: { name, value, title } }, section) => {
    //     console.log({ target: { name, value, title } })
    //     dispatch(addOrderData({
    //         [name]: value
    //     }))
    // }

    

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    // si esta undefined o colapsa por orderInfo.PymntGroup.code tienen que agregarlo de sap por que eso no //
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

     // suma el total de los bultos pedidos
     const [totalCantidad, setTotalCantidad] = useState(0);

     useEffect(() => {
        const calculateTotalCantidad = () => {
          const total = orderInfo.DocumentLines.reduce((acc, item) => acc + item.Cantidad, 0);
          //console.log(total)
          setTotalCantidad(total);
        };
    
        calculateTotalCantidad();
      }, [orderInfo.DocumentLines]);
    
    return (
        <>
            <form
                id='ordFormRef'
                className="form-group position-relative py-4 px-md-5 px-sm-3 px-2"
                onKeyDown={(e) => checkKeyDown(e)}
                onSubmit={handleSubmitOrder}
                name={userData.Permiso === 'Vendedor' ? 'Pendiente' : 'Autorizado'}
            >
                {/* ROW 1 */}
                {/* BUSCADOR DE CLIENTE */}
                <div className='row g-3 mb-3 align-items-center'>
                    <div className="position-relative col-7">
                        <label htmlFor='OCCuit'
                            className='inputLabel position-absolute ms-2 px-1 textSmall textColorGreyL'>
                            Cliente
                        </label>

                        <input list='clientsL' id="OCCuit" type="text" name="cuit" autoComplete="off"
                            placeholder={orderInfo.Cliente ? orderInfo.Cliente : "cuit-cliente"}
                            className="form-control inputs"
                            onChange={onClientChange}
                            disabled={section === 'MODIFICAR'}
                        />

                        {
                            clientes &&

                            <datalist id='clientsL'>
                                {
                                    clientes
                                        .map((cli) => {
                                            return <option name={cli.Cliente_ID}
                                                key={clientes.indexOf(cli)}
                                            >{`${cli.CUIT} - ${cli.Cliente}`} </option>
                                        })
                                }
                            </datalist>
                        }

                    </div>
                    <div className="position-relative col-5">
                        <label htmlFor='numOrden'
                            className='inputLabel position-absolute ms-2 px-1 textSmall textColorGreyL'>
                            # Pedido
                        </label>
                        <input
                            type='text' id="numOrden"
                            className='form-control inputs'
                            placeholder={orderInfo.NumPedidoDelCliente ? orderInfo.NumPedidoDelCliente : '#'}
                            name='NumPedidoDelCliente' onChange={(e) => onInputChange(e, section)} autoComplete="off"
                        />
                    </div>
                </div>

                {/* ROW 2 */}
                <div className="row g-3 mb-3 ">

                    {/* ROW 2.1 */}

                    {/*TIPO DE PEDIDO */}
                    <div className="col-sm-5 col-6 d-flex ">
                        <div className="position-relative w-100">
                            <label
                                htmlFor='tipoPedido'
                                className='inputLabel inputLabelShort position-absolute px-1 textSmall textColorGreyL'
                            >
                                Tipo
                            </label>

                            <select
                                type="text"
                                name="TipoPedido"
                                id='tipoPedido'
                                disabled={!orderInfo.Cliente}
                                className='form-control inputs textColorGreyM '
                                onChange={(e) => onInputChange(e, section)}
                                autoComplete="off"
                            >
                                {
                                    tiposPedidos.map((tipo) => {
                                        return <option
                                            name={tipo}
                                            value={tiposPedidos.indexOf(tipo) + 1}
                                            key={tipo}
                                        >
                                            {tipo}
                                        </option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    {/* Con PAGO */}
                    <div className=" col-sm-5 col-6 position-relative">
                        <label
                            htmlFor='pymntGroup'
                            className='inputLabel position-absolute ms-2 px-1 textSmall textColorGreyL'
                        >
                            Pago
                        </label>

                      
                            <select
                                type="text"
                                name="PymntGroupCode"
                                id='pymntGroup'
                                className='form-control inputs textColorGreyM'
                                disabled={!orderInfo.Cliente}
                                onChange={(e) => onPymntChange(e, section)}
                                autoComplete="off"
                                value={orderInfo.PymntGroup ? orderInfo.PymntGroup.PymntGroup : ''}
                              
                                >

                              <option> {orderInfo.PymntGroup ? orderInfo.PymntGroup.PymntGroup : 'seleccionar'}</option>
                                {
                                    condicionesDePago.map((cond) => {
                                        if (cond.Habilitado) {
                                            return <option
                                                value={cond.GroupNum}
                                                key={cond.GroupNum}
                                                title={cond.PymntGroup}
                                            >
                                                {cond.PymntGroup}
                                            </option>
                                        }
                                    })
                                }
                        </select>
                    </div>


                    {
                      (orderInfo.TipoPedido === '3' || orderInfo.TipoPedido === '4')
                        &&
                        <div className="row gx-0 col-12 col-sm-6 mt-3 px-2">
                            <div className="col-4 position-relative">
                                <label
                                    htmlFor='mesSelecc'
                                    className='inputLabel inputLabelShort position-absolute px-1 textSmall textColorGreyL'>
                                    Mes
                                </label>

                                <select
                                    type="text"
                                    name="MesSelecc"
                                    id='mesSelecc'
                                    value={orderInfo.MesSelecc || "-"}
                                    className='form-control inputs'
                                    onChange={(e) => onInputChange(e, section)}
                                    autoComplete="off"
                                >
                                    {
                                        meses.map((mes) => {
                                            return <option
                                                name={mes}
                                                value={('0' + meses.indexOf(mes)).slice(-2)}
                                                key={mes}
                                            >
                                                {mes}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-4 position-relative">
                                <label
                                    htmlFor='anioSelecc'
                                    className='inputLabel inputLabelShort position-absolute px-1 textSmall textColorGreyL'>
                                    Año
                                </label>
                                <select
                                    type="text"
                                    name="AnioSelecc"
                                    id='anioSelecc'
                                    className='form-control inputs'
                                    onChange={(e) =>  onInputChange(e, section)}
                                    autoComplete="off"
                                    value={orderInfo.AnioSelecc || "-"}
                                >
                                    {
                                        ['', new Date().getFullYear(), new Date().getFullYear() + 1]
                                            .map((anio) => {
                                                return <option name={anio} key={anio}>{anio} </option>
                                            })
                                    }
                                </select>
                            </div>

                            <div className="col-4 position-relative">

                                {/* /* ENTREGAS */}
                                <label
                                    htmlFor='cantEntrega'
                                    className='inputLabel inputLabelShort inputLabelShort position-absolute px-1 textSmall textColorGreyL'
                                >
                                    Entregas
                                </label>

                                <input
                                    type="number"
                                    name="CantEntregas"
                                    value={orderInfo.CantEntregas}
                                    className='form-control inputs'
                                    onChange={(e) => onInputChange(e, section)}
                                    autoComplete="off"
                                >
                                </input>
                            </div>
                        </div>
                    }
                </div>


                {/* COLUMNA DATOS DE FACTURACION */}
                <div className='row gx-0 pb-3 pt-2'>
                    <h5 className="col-sm-4 col-12 fw-bold">
                        FACTURACION
                    </h5>

                    <div className="col-sm-8 col-12 d-flex flex-column gap-3 ps-4">

                        {/* DIRECCION */}
                        <details id='detailsFacturacion'>
                            <summary className="d-flex align-items-center">
                                <img src={homeIco} className="hw18px me-2" alt="dir_fact" />

                                {
                                    orderInfo.Direccion_Facturacion_New
                                        ?
                                        <span>
                                            {orderInfo.Direccion_Facturacion_New.BillToStreet} &nbsp;
                                            {orderInfo.Direccion_Facturacion_New.BillToStreetNo} &nbsp;
                                            {orderInfo.Direccion_Facturacion_New.BillToState}, &nbsp;
                                            {orderInfo.Direccion_Facturacion_New.BillToCity} &nbsp;
                                            {orderInfo.Direccion_Facturacion_New.BillToCountry}
                                            {orderInfo.Direccion_Facturacion_New.BillToZipCode}
                                        </span>
                                        :
                                        orderInfo.Direccion_Facturacion
                                            ?
                                            <span>
                                                {
                                                    orderInfo.Direccion_Facturacion + ' - ' +
                                                    orderInfo.Provincia_Facturacion + ' - (' +
                                                    orderInfo.Cod__Postal_Factu + ')'
                                                }
                                            </span>
                                            :
                                            <span className="textRed">
                                                Seleccione un domicilio de facturacion
                                            </span>
                                }
                            </summary>

                            <div className={`d-flex flex-column mb-2 ${!orderInfo.Cliente ? 'd-none' : ''}`}>
                                {/* 
                                <Autocomplete
                                    name='Direccion_Facturacion_New'
                                    className='inputs form-control mt-2 mb-1'
                                    // onChange={() => console.log('change')}
                                    placeholder='Buscar'
                                    id='Direccion_Facturacion_New'
                                    apiKey={process.env.REACT_APP_MAP}
                                    onPlaceSelected={(place) =>
                                        handleSelectPlace('Direccion_Facturacion_New', 'detailsFacturacion', place?.address_components)
                                    }
                                    options={{
                                        types: ['address'],
                                        componentRestrictions: { country: 'ar' },
                                        //fields: ['postal_code']
                                    }}
                                    onClick={() => { document.getElementById('Direccion_Facturacion_New').classList.remove('clrBorderRed') }}
                                />
                                */}

                                {/* lista de direcciones alternativas */}
                                {
                                    orderInfo.Direccion_Facturacion
                                    &&
                                    <ul className="fontSize14 border mb-0 mt-3 py-2 position-relative clrBgWhite addressUL">
                                        <label

                                            className='inputLabel inputLabelShort position-absolute px-1 textSmall textColorGreyL'
                                        >
                                            Otras Direcciones
                                        </label>
                                        <li
                                            className="cursorPointer"

                                            onClick={() => onAddAddress(
                                                'Direccion_Facturacion_New',
                                                'detailsFacturacion',
                                                {
                                                    BillToStreet: orderInfo.Direccion_Facturacion ? orderInfo.Direccion_Facturacion : '',
                                                    BillToStreetNo: orderInfo.Provincia_Facturacion ? orderInfo.Provincia_Facturacion : '',
                                                    BillToZipCode: orderInfo.Cod__Postal_Factu ? orderInfo.Cod__Postal_Factu : ''
                                                }
                                            )}
                                        >
                                            {
                                                orderInfo.Direccion_Facturacion + ' - ' +
                                                orderInfo.Provincia_Facturacion + ' - (' +
                                                orderInfo.Cod__Postal_Factu + ')'
                                            }
                                        </li>
                                        {/* TODO DIRECCIONES */}
                                    </ul>
                                }
                            </div>
                        </details>
                        {/* </div> */}

                        {/* transp - tel */}
                        {/* <div> <span className="textColorGreyL  me-2">&#9993;</span> {orderInfo.Email} </div>
                            <div> <span className="textColorGreyL  me-2">&#9990;</span> {orderInfo.Telefono} </div> */}
                    </div>
                </div>

                {/* COLUMNA DATOS DE ENTREGA */}
                <div className='row gx-0 pt-3'>
                    <div className="col-sm-4 col-12 d-flex flex-column flexRowSM gap-1">

                        <h5 className=" fw-bold">
                            ENTREGA
                        </h5>

                        {
                            orderInfo.Cliente
                                ? orderInfo.Turno !== 'Si'
                                    ? <span className="fw-bold ms-1 mb-3">SIN Turno</span>
                                    : <>
                                        <span className="fw-bold ms-1 mb-3">
                                            CON Turno
                                        </span>
                                        <div className="position-relative">
                                            <label htmlFor='contactTurnoInput'
                                                className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                                Contacto Turno
                                            </label>
                                            <textarea
                                                className="w-100  maxWAviable form-control inputs textColorGrey"
                                                maxLength="254"
                                                rows="1"
                                                id="contactTurnoInput"
                                                name="Contacto_Turno"
                                                value={orderInfo.Contacto_Turno}
                                                onChange={(e) => onInputChange(e, section)}
                                            >
                                            </textarea>
                                        </div>
                                    </>
                                : ''
                        }


                    </div>
                    <div className="col-sm-8 col-12 d-flex flex-column gap-3 ps-4">

                        {/* DIRECCION */}
                        <div className="d-flex align-items-center ">

                            <details id="detailsEntrega">
                                <summary className="d-flex align-items-center">
                                    <img src={homeIco} className="hw18px me-2" alt="dir_entr" />

                                    {
                                        orderInfo.Direccion_Entrega_New
                                            ?
                                            <span>
                                                {orderInfo.Direccion_Entrega_New.ShipToStreet} &nbsp;
                                                {orderInfo.Direccion_Entrega_New.ShipToStreetNo} &nbsp;
                                                {orderInfo.Direccion_Entrega_New.ShipToState}, &nbsp;
                                                {orderInfo.Direccion_Entrega_New.ShipToCity} &nbsp;
                                                {/* ({orderInfo.Direccion_Entrega_New.cp}), &nbsp; */}
                                                {orderInfo.Direccion_Entrega_New.ShipToCountry}

                                            </span>
                                            :
                                            orderInfo.Direccion_Entrega
                                                ?
                                                <span>
                                                    {
                                                        orderInfo.Direccion_Entrega + ' - ' +
                                                        orderInfo.Provincia_Entrega
                                                        // + ' - (' + orderInfo.Cod__Postal_Entrega + ')'
                                                    }
                                                </span>
                                                :
                                                <span className="textRed">
                                                    Seleccione un domicilio de entrega
                                                </span>
                                    }
                                </summary>

                                <div className={`d-flex flex-column mb-2 ${!orderInfo.Cliente ? 'd-none' : ''}`}>

                                    <div className="d-flex position-relative mt-2 mb-1">
                                        <img
                                            className="h-75 position-absolute mx-1 align-self-center"
                                            src={searchIco}
                                            alt="o"
                                        />
                                        {/* <Autocomplete
                                            name='Direccion_Entrega_New'
                                            ref={inputEntrega}
                                            className='inputs form-control ps40'
                                            placeholder='Buscar'
                                            id='Direccion_Entrega_New'
                                            apiKey={process.env.REACT_APP_MAP}
                                            onPlaceSelected={(place) =>
                                                handleSelectPlace('Direccion_Entrega_New', 'detailsEntrega', place?.address_components)
                                            }
                                            options={{
                                                types: ['address'],
                                                componentRestrictions: { country: 'ar' },
                                            }}
                                            onClick={removeErrorInput}
                                        /> */}
                                    </div>

                                    {/* DIRECCIONES DE ENTREGA */}
                                    <ul className="fontSize14 border mb-0 mt-3 pe-2 py-2 clrBgWhite addressUL position-relative">
                                        <label
                                            className='inputLabel inputLabelShort position-absolute px-1 textSmall textColorGreyL'
                                        >
                                            Otras Direcciones
                                        </label>

                                        {
                                            orderInfo.Direccion_Entrega
                                            &&
                                            // direccion original
                                            <li
                                                className="cursorPointer"
                                                onClick={() => onAddAddress(
                                                    'Direccion_Entrega_New',
                                                    'detailsEntrega',
                                                    {
                                                        ShipToStreet: orderInfo.Direccion_Entrega ? orderInfo.Direccion_Entrega : '',
                                                        ShipToCity: orderInfo.Provincia_Entrega ? orderInfo.Provincia_Entrega : '',
                                                    },
                                                    section
                                                )}
                                            >
                                                {
                                                    orderInfo.Direccion_Entrega + ' ' +
                                                    orderInfo.Provincia_Entrega
                                                }
                                            </li>
                                        }

                                        {/* direcciones extras */}
                                        {/* TODO DIRECCIONES */}
                                        {
                                            clientInfo.Direcciones_Extra
                                            &&
                                            clientInfo.Direcciones_Extra
                                                .map(dir => (
                                                    dir.Calle
                                                        ?
                                                        <li
                                                            key={clientInfo.Direcciones_Extra.indexOf(dir)}
                                                            className="cursorPointer mb-1"
                                                            onClick={() => onAddAddress(
                                                                'Direccion_Entrega_New',
                                                                'detailsEntrega',
                                                                {
                                                                    ShipToStreet: dir.Calle ? dir.Calle : '',
                                                                    ShipToStreetNo: dir.Número_de_la_calle ? dir.Número_de_la_calle : '',
                                                                    ShipToCity: dir.Ciudad ? dir.Ciudad : '',
                                                                    ShipToZipCode: dir.Código_postal ? dir.Código_postal : '',
                                                                    ShipToCountry: dir.País ? dir.País : '',
                                                                    ShipToDef:dir.Nombre ? dir.Nombre : ''
                                                                },
                                                                section
                                                            )}
                                                        >
                                                            {parseAddress(dir)}
                                                        </li>
                                                        :
                                                        <span key={clientInfo.Direcciones_Extra.indexOf(dir)}></span>
                                                ))
                                        }

                                    </ul>
                                </div>
                            </details>
                        </div>

                        <div className="d-flex gap-3 flexColumnXs">

                            {/* transp - tel */}
                            <div className="position-relative ">

                                <label htmlFor='transp'
                                    className='inputLabel position-absolute ms-2 px-1 textSmall textColorGreyL'>
                                    Transporte
                                </label>
                                <input
                                    type='text' id="transp"
                                    className='form-control inputs'
                                    disabled={!orderInfo.Cliente}
                                    placeholder={orderInfo.Transporte ? orderInfo.Transporte : ''}
                                    name='Transporte' autoComplete="off"
                                    onChange={(e) => onInputChange(e, section)}
                                />
                            </div>

                            <div className="position-relative">

                                <label htmlFor='telTransp'
                                    className='inputLabel position-absolute ms-2 px-1 textSmall textColorGreyL'>
                                    Tel. Transp
                                </label>
                                <input
                                    type='text' id="telTransp"
                                    className='form-control inputs'
                                    disabled={!orderInfo.Cliente}
                                    placeholder={orderInfo.TelTransporte ? orderInfo.TelTransporte : ''}
                                    name='TelTransporte' autoComplete="off"
                                    onChange={(e) => onInputChange(e, section)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* FILA DESCUENTOS */}
                <h5 className="pt-4 pb-2 fw-bold">DESCUENTOS</h5>

                <div className="row gx-0 pb-3">

                    <div className='text-center col-4 position-relative'>
                        <label htmlFor='df'
                            className='inputLabel inputLabelCenter position-absolute textSmall textColorGreyL'>
                            % DF
                        </label>
                        <input type='number' placeholder={'%'}
                            id='df'
                            disabled={!orderInfo.Cliente}
                            className='inputs form-control m-auto w75SM minW79px w-50'
                            name="Descuento03"
                            value={orderInfo.Descuento03}
                            onChange={(e) => onInputChange(e, section)}
                        />
                    </div>

                    <div className='text-center col-4 position-relative'>
                        <label htmlFor='dc'
                            className='inputLabel inputLabelCenter position-absolute textSmall textColorGreyL'>
                            % DC
                        </label>
                        <input type='number' placeholder={'%'}
                            id='dc'
                            disabled={!orderInfo.Cliente}
                            className='inputs form-control m-auto w75SM minW79px w-50'
                            name="Descuento02"
                            value={orderInfo.Descuento02}
                            onChange={(e) => onInputChange(e, section)}
                        />
                    </div>

                    <div className='text-center col-4 position-relative'>
                        <label htmlFor='dpp'
                            className='inputLabel inputLabelCenter position-absolute textSmall textColorGreyL'>
                            % DPP
                        </label>
                        <input type='number' placeholder='%'
                            id='dpp'
                            disabled={!orderInfo.Cliente}
                            name="Descuento01"
                            value={orderInfo.Descuento01}
                            className='inputs form-control m-auto w75SM minW79px w-50'
                            onChange={(e) => onInputChange(e, section)}
                        />
                    </div>
                </div>

                {/* FILA DETALLE */}
                <div className='d-flex flex-wrap justify-content-between align-self-center pt-4 pb-2 pe-2'>
                    <div className="flexRowSM gap-1">
                        <h5 className="fw-bold ">DETALLE</h5>
                    </div>
                    <div className=' d-flex gap-2 ps-4 mb-2'>
                        <TextSwitch
                            onInputChange={onInputChange}
                            section={section}
                            text={'Calef'} checked={orderInfo.CategoriaMup.includes('Calef')}
                            property={"CategoriaMup"}
                        />
                        <TextSwitch onInputChange={onInputChange} section={section} text={'Venti'} checked={orderInfo.CategoriaMup.includes('Venti')} property={"CategoriaMup"} />
                        <TextSwitch onInputChange={onInputChange} section={section} text={'Pad'} checked={orderInfo.CategoriaMup.includes('Pad')} property={"CategoriaMup"} />
                    </div>
                </div>

                {/* PRODUCT FORM */}
                {/* agrega una linea por cada producto que se agregue al pedido (id de cada input con el index adelante) */}
                {
                    orderInfo.DocumentLines
                    &&
                    orderInfo.DocumentLines.map((p,index) =>

                        <div key={`key${orderInfo.DocumentLines.indexOf(p)}`}
                            id={`${orderInfo.DocumentLines.indexOf(p)}id`}
                            className="row gx-0 mt-2">

                            <div className="row gx-2 col-11 mt-2 px-0">

                                <div className="col-md-6 col-12 mb-2 position-relative">
                                    <label htmlFor={`${orderInfo.DocumentLines.indexOf(p)}sku`}
                                        className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                       {/* Producto */}
                                    </label>
                                    <label className="inputLabel position-absolute px-1 textSmall textColorGreyL">
                                        pvp
                                        <span className="fw-bold ps-1">
                                            {
                                                orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Pvp
                                                    ?
                                                    formatter.format(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Pvp)
                                                    : ''
                                            }
                                        </span>
                                    </label>

                                        {/* Tengo que poner  un validador que si .Bono tiene contenido mostrar otro label, 
                                            osea una variacion de ese label,
                                            con una cuenta dentro que la tiene que hacer cada vez que se modifique el valor
                                        */}
                                            <label className="inputLabel labelEnd position-absolute px-1 textSmall textColorGreyL">
                                                costo
                                                <span className="fw-bold ps-1">
                                                    {/* {
                                                            orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].CostoSinIva
                                                            ?
                                                            formatter.format(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].CostoSinIva)
                                                            : ''
                                                    } */}
                                                    {ContarCostoCorrectTemporal(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Pvp,orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Bono,orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].MUP,orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].CostoSinIva)}
                                                </span> 
                                            </label>


                                    <input list='productsL' type="text" name="sku"
                                        id={`${orderInfo.DocumentLines.indexOf(p)}sku`}
                                        title={p.Descripcion ? p.Descripcion : 'SKU - Producto'}
                                        placeholder={p.Articulo ? p.Articulo + "-" + p.Descripcion : "sku - producto"}
                                        className={`form-control inputs textColorGreyM ${inputRepeated(orderInfo.DocumentLines.indexOf(p)) ? 'clrBorderRed' : ''}`}
                                        onChange={(e) => onPoductChange(e, section)}
                                        disabled={!orderInfo.Cliente}
                                        autoComplete="off"
                                        ref={lastInput}
                                    />
                                    {
                                        precios
                                        &&
                                        <datalist id='productsL'>
                                            {
                                                precios.map((pro) => {
                                                    return <option name={pro.ItemCode} key={pro.ItemCode}>
                                                        {`${pro.ItemCode} - ${pro.ItemName}`}
                                                    </option>
                                                })
                                            }
                                        </datalist>
                                    }
                                </div>


                                <div className="col-md-2 col-sm-4 col-6 mb-2 position-relative">
                                    <label htmlFor={`${orderInfo.DocumentLines.indexOf(p)}Cantidad`}
                                        className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                        Cant
                                    </label>
                                    <input
                                        id={`${orderInfo.DocumentLines.indexOf(p)}Cantidad`}
                                        className="form-control inputs textColorGreyM"
                                        type="number"
                                        title="Cantidad"
                                        name="Cantidad"
                                        value={
                                            orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Cantidad
                                                ? parseInt(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Cantidad)
                                                : ''
                                        }
                                        onChange={(e) => onDetailInputChange(e, section)}
                                        disabled={!orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].Articulo}
                                        min={0}
                                    />
                                </div>

                                <div className="col-md-2 col-sm-4 col-6 position-relative">
                                    <label htmlFor={`${orderInfo.DocumentLines.indexOf(p)}MUP`}
                                        className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                        Mup
                                    </label>
                                    <input
                                        id={`${orderInfo.DocumentLines.indexOf(p)}MUP`}
                                        className="form-control inputs textColorGreyM"
                                        type="number"
                                        title="MUP"
                                        name='MUP'
                                        value={orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].MUP}
                                        onChange={(e) => onDetailInputChange(e, section)}
                                        disabled={!orderInfo.Cliente}
                                    />
                                </div>

                                

                                <div className="col-md-2 col-sm-4 col-6 position-relative">
                                    <label className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                        Bono $
                                    </label>
                                    <input
                                        id={`${orderInfo.DocumentLines.indexOf(p)}Bono`}
                                        className="form-control inputs textColorGreyM"
                                        type="number"
                                        title="Bono"
                                        name='Bono'
                                        value={orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].descuentoPvp}
                                        onChange={(e) => onDetailInputChange(e, section)}
                                        disabled={!orderInfo.Cliente}
                                        min= '0'
                                    />
                                </div>

                                <div className="col-md-2 col-sm-4 col-6 position-relative d-flex justify-content-center">
                                    <label htmlFor={`${orderInfo.DocumentLines.indexOf(p)}MUP`}
                                        className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                                        sum costo 
                                    </label>

                                    <span className="display-flex align-self-start">
                                        {
                                            orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].sumTriCostoconIva && clientInfo.liquidaIva === 'S' && clientInfo.Iva === 'EX'
                                            ? formatter.format(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].sumTriCostoconIva)
                                            : (
                                                orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].sumDupliCostoSinIva 
                                                ? formatter.format(orderInfo.DocumentLines[orderInfo.DocumentLines.indexOf(p)].sumDupliCostoSinIva) 
                                                : '-'
                                            )
                                        }
                                    </span>
                                    </div>
                            </div>

                            <button
                                name="Delete"
                                id={`${orderInfo.DocumentLines.indexOf(p)}Delete`}
                                type="button"
                                className="btn-close ms-auto centerEnd text-end align-self-center"
                                onClick={(e) => deleteProduct(e, section)}
                            />

                        </div>
                    )
                }
                
                {
                totalCantidad != 0 && <div className="centerEnd text-end align-self-center">
                                         <p>Producto Total : {totalCantidad}</p>
                                    </div>
                }
                {/* BOTON AGREGAR + */}
                <div className="col-12 m-auto pb-3 ">
                    <button type="button"
                        className='btn btn-block btn-outline-primary w-100 mt-3 btnBlue'
                        onClick={addProductToList}
                        disabled={!addProductValidation() || !repeatedOk}
                    >
                        + Productos
                    </button>
                </div>

                {/* FILA COMENTARIOS */}
                <div className='d-flex mt-4 text-end align-items-center textLeftSM position-relative'>
                    <label htmlFor='comentariosInput'
                        className='inputLabel position-absolute px-1 textSmall textColorGreyL'>
                        Comentarios
                    </label>
                    <textarea
                        className="w-100  form-control inputs textColorGrey"
                        name="Comments"
                        maxLength="254"
                        id="comentariosInput"
                        rows="1"
                        value={orderInfo.Comments}
                        onChange={(e) => onInputChange(e, section)}
                    >

                    </textarea>
                </div>

                {/* DETALLE */}
                <div
                    id="valuesBox"
                    className="row gy-4 gx-0 px-4 pb-4 pt-1 my-4 clrBgGreyMd"
                >

                    <div className="col-6 col-md-4" >
                        <div className="fw-bold textColorGreyM">
                            Neto
                        </div>
                        <div className="">
                            {
                                orderInfo.sumTotalConIvaTripl && 
                                clientInfo.liquidaIva === 'S' && 
                                clientInfo.Iva === 'EX' 
                                ? formatter.format(orderInfo.sumCostoConIvaTripl)
                                : formatter.format(orderInfo.Neto)
                            }
                        </div>
                    </div>

                    <div className="col-6 col-md-4 text-center textRightMD">
                        <div className="fw-bold textColorGreyM">
                            DF + DC + DPP:  {orderInfo.DescuentoTotal} %
                        </div>
                        <div className="">
                            {formatter.format(orderInfo.Neto * (orderInfo.DescuentoTotal / 100))}
                        </div>
                    </div>

                    <div className="col-6 col-md-4 text-end textLeftMD">
                        <div className="fw-bold textColorGreyM">
                            SubTotal
                        </div>
                        <div className="">
                        {
                            orderInfo.sumTotalConIvaTripl && 
                            clientInfo.liquidaIva === 'S' && 
                            clientInfo.Iva === 'EX' 
                            ? formatter.format(orderInfo.sumTotalConIvaTripl)
                            : formatter.format(orderInfo.SubTotal)
                        }
                            
                        </div>
                    </div>

                    <div className="col-6 col-md-4 text-start textRightMD">
                        <div className="fw-bold textColorGreyM">
                            IVA 21%
                        </div>
                        <div className="">
                            {formatter.format(orderInfo.IVA21)}
                        </div>
                    </div>

                    {/*
                        <div className="col-6 col-md-4 text-start textRightMD">
                            <div className="fw-bold textColorGreyM">
                            Descuento total bono
                            </div>
                            <div className="">
                            {formatter.format(orderInfo.TotalBono)}
                            </div>
                        </div> 
                    */}

                    {/* ///////////IMPUESTOS///////////// */}
                    <div className="col-md-4 text-end mt1XS borderTopMD" >
                        <div className="fw-bold fontSize24 textColorGreyM">
                            Total       
                        </div>
                        <div className="fontSize24">
                        {
                            orderInfo.sumTotalConIvaTripl && 
                            clientInfo.liquidaIva === 'S' && 
                            clientInfo.Iva === 'EX' 
                            ? formatter.format(orderInfo.sumTotalConIvaTripl)
                            : formatter.format(orderInfo.Total) 
                        }

                        </div>
                    </div>

                </div>

                {/* BOTONES */}


                <div className="row justify-content-between gx-0">
                    {
                        section !== 'MODIFICAR'
                            ? <>
                                <input type="button" className='btn btn-outline-secondary col-5 text-uppercase fw-bold'
                                    disabled={submitValidator()}
                                    name='Preliminar'
                                    onClick={onSubmitOrderForm}
                                    value='Preliminar'
                                />

                                <input type="submit" className='btn btn-outline col-5 btnBlue text-uppercase fw-bold'
                                    value="Guardar"
                                    disabled={submitValidator()}
                                />
                            </>
                            : <>
                                <span className="col-5"></span>


                                <button type="submit" className='btn btn-outline col-5 btnBlue'
                                    disabled={submitValidator() || saving}
                                >
                                    {

                                        !saving
                                            ?
                                            <span className="text-uppercase fw-bold">Guardar</span>
                                            :
                                            <div className="spinner-border spinner-border-sm " role="status" />
                                    }
                                </button>


                            </>
                    }
                </div>



            </form >

        </>
    )
}
