import {createSlice} from '@reduxjs/toolkit'

const initialState = {a: false, b: false}

export const OpenModalSlices = createSlice({
    name: 'openModal',
    initialState,
    reducers: {
        //recibe false para cerrar
        modalClose: (state, action) => {
            // return initialState
            return { a: false, b: false };
        },

        //recibe true para abrir y crea el registro
        modalOpen: (state, action) => {

            window.history.pushState({}, '', '');
            return {...state, ...action.payload }
        }
    }
})

export const {modalClose, modalOpen} = OpenModalSlices.actions
export default OpenModalSlices.reducer