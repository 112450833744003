import { doc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { useGeneral } from '../../context/generalContext';
import { Adddata, db, updateRevision } from '../../data/index';
import { modalOpen } from '../../store/openModal/OpenModalSlices';
import { SlackErrorHandle, ToastError, ToastSucces, ToastWarn } from '../tools/index';
import { useEffect, useState } from 'react';
import { CreateOrder, NotificationModal } from './index';
import { addModification } from '../../store/orderData/OrderDataSlices';

export const OrderDetailButtons = ({ closeSection }) => {
   
    const userData = useSelector((state) => state.user)
    const orderInfo = useSelector((state) => state.orderData)
    const isOpenModal = useSelector(state => state.openModal)
    const sapConnected = useSelector(state => state.sapConnected)
    const clients = useSelector(state => state.clients)
    const downloading = useSelector((state) => state.downloadElements)
    
    const [cargandoSap, setCargandoSap] = useState(false)
    const [autorizando, setAutorizando] = useState(false)
    const [taxes, setTaxes] = useState([])
    const [disabledBtn, setDisabledBtn] = useState(true)

    // revisar
    const [dis, setDis] = useState(true)

    const dispatch = useDispatch()
    //Instancia para conexion con el service Layer
    const { uploadtoServiceLayer, pedidosCollectionFirebase, datacred, Sl, getToday } = useGeneral()

    //console.log(orderInfo.proforma)
    //TODO: probar con sap
    //funcion para cambiar el estado del pedido (aceptado / rechazado)
    const handleOrderState = async (name) => {
        setDisabledBtn(true)
        ToastWarn('Procesando carga...')
        try {
            //Si el boton es cargar a sap entonces enviar a Service Layer
            if (name === 'Cargado en SAP') {

                setCargandoSap(true)
                try {
                    // subida de proforma al pedido 
                    let prof = calculateTaxes()

                   // sube primero la orden a sap agregando los datos de proforma y actualiza firebase
                    await uploadtoServiceLayer({ ...orderInfo, Proforma: prof }, userData.Apellido)

                    //TODO: probar uploadtoServiceLayer sube a firebase tambien
                    //luego actualiza en firebase
                    await Adddata(
                        doc(db, pedidosCollectionFirebase, orderInfo.ID),
                        { Proforma: prof }
                    )
                    closeSection()
                } catch (error) {
                    ToastError(`fallo en la carga ${error}`)
                    SlackErrorHandle(`${new Date()}: -> fallo en la carga -> ${error} -> order Id : ${orderInfo.ID}`)
                }

            } else {
                setAutorizando(true)
                await updateRevision(pedidosCollectionFirebase, orderInfo.ID, userData.Apellido, name)

                closeSection()
                ToastSucces('Orden Autorizada')
            }
        } catch (error) {
            ToastError('error al cargar en sap')
            console.log(error)
            SlackErrorHandle(`${new Date()}: -> error al cargar en sap -> ${error} -> order Id : ${orderInfo.ID}`)
        }
    }

    //retorna si el usuario ya hizo la revision o es el mismo vendeor.......
    const sameReviewer = () => {
        return (
            (userData.Nombre + ' ' + userData.Apellido === orderInfo.Revision)
            || (userData.Nombre + ' ' + userData.Apellido === orderInfo.Vendedor)
        )
    }


    // funcion que activa los botones segun sea el caso
    const disableButton = () => {
        let disabled = true
        
        //estado pendiente
        if (orderInfo.Estado === 'Pendiente') {
            disabled = false
            // setDis(false)
        //permiso direccion autorizado y estado autorizado
        } else if
            (orderInfo.Estado === 'Autorizado' &&
            (userData.Permiso === 'Direccion' || userData.Permiso === 'Gerencia')) {
                
            disabled = false
            // setDis(false)
        } else if (!cargandoSap) {
            disabled = false
            // setDis(false)
        }

        return disabled
    }

    const handleModifications = () => {
        dispatch(modalOpen({ b: true }))
        dispatch(addModification({
            Apellido: userData.Apellido,
            UID: userData.UID,
            fecha: new Date().toLocaleDateString()
        }))
    }

    /**************************************** */
    //get impuestos por cliente, codigos, fecha 
    //calculos de impuestos
    useEffect(() => {
        setCargandoSap(true)
        const fetchData = async () => {
          
            if (
                sapConnected
                && !Object.values(downloading).includes(false)
                && !["Cargado en SAP", "Rechazado"].includes(orderInfo.Estado)
            ) {
                
                let cliente = clients.find((e) => e.CUIT === orderInfo.Cuit).Cliente_ID
               
                try {
                    const getTaxes = async (url, ServiceLayer, cliente) => {
                        
                        let date = getNewToday()

                        try {
                            const urldata = `sml.svc/PV_CLIENTES_IMPUESTOS?$filter=CardCode eq '${cliente}' and (WTCode eq 'P007' or WTCode eq 'P013' or WTCode eq 'PC01' or WTCode eq 'PC02' or WTCode eq 'P016') and DateTo gt '${date}' `
                            //console.log(urldata)  
                            const res = await ServiceLayer(url).get(urldata)

                            return (res.data.value)
                        } catch (error) {
                            console.log(error.response.data.error.message.value)
                            throw error
                        }
                    }
                    try {
                        //console.log(datacred)
                        let taxes = await getTaxes(datacred.BaseURL, Sl, cliente)
                       // console.log(taxes)
                        setTaxes(taxes)

                    } catch (error) {
                        console.log(error.message)
                    }
                } catch (error) {
                    console.log(error.message)
                } finally {
                    setCargandoSap(false)
                }
            }
        }
        fetchData()

    }, [downloading])

    const getNewToday = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Asegura que el mes tenga dos dígitos
        const day = String(today.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos
        return `${year}-${month}-${day}`;
    };
   
    //calcula los impuestos
    const calculateTaxes = () => {  
        
        let proforma = {
            numProforma: 'P-' + orderInfo.Num_pedido,
            fecha: new Date().toLocaleDateString(),
            P007: 0,
            P013: 0,
            PC01: 0,
            PC02: 0,
            P016: 0,
            PC05: 0,
            PC06: 0,
            PC07: 0,
            PC08: 0,
            PorcentajeIBB:{},
        }
        //console.log(proforma)
        // PC05 = Subtotal * Tasa/100
        // PC06 = Subtotal * 50 / 100 * Tasa/100
        // PC07 = Subtotal + IVA * Tasa/100
        // PC06 = Subtotal + IVA * 50 / 100 * Tasa/100
        let impSum = orderInfo.IVA21
        
        if (taxes) {
            taxes.map((t) => {

                if (t.Tasa > 0) {
                    // if (t.WTCode === 'PC05') {
                    //         let value = orderInfo.SubTotal * t.Tasa / 100;  
                    //         proforma = {
                    //             ...proforma,
                    //             [t.WTCode]: value, 
                    //         }
                    //     } else 
                    // if (t.WTCode === 'PC06') {
                    //        let value = (orderInfo.SubTotal * 0.5) * t.Tasa / 100;
                    //         proforma = {
                    //             ...proforma,
                    //             [t.WTCode]: value, 
                    //         }
                    //      } else
                    // if (t.WTCode === 'PC07') {
                    //         let value = (orderInfo.SubTotal + orderInfo.IVA21) * t.Tasa / 100;
                    //         proforma = {
                    //             ...proforma,
                    //             [t.WTCode]: value, 
                    //         }
                    //     } else 
                    // if (t.WTCode === 'PC08') {
                    //         let value = ((orderInfo.SubTotal + orderInfo.IVA21) * 0.5) * t.Tasa / 100;
                    //         proforma = {
                    //             ...proforma,
                    //             [t.WTCode]: value, 
                    //         }
                    //     } else 
                         if (t.WTCode !== 'PC01') {
                                let value = orderInfo.SubTotal * t.Tasa / 100
                                // total de la orden X tasa del impuesto / 100
                                let secondValue = t.Tasa / 100 // asi estaba antes  t.Tasa / 100
                                proforma = {
                                    ...proforma,
                                    [t.WTCode]: value, 
                                    PorcentajeIBB:{
                                        ...proforma.PorcentajeIBB,
                                        [t.WTCode]: t.Tasa,
                                    },
                                }
                        impSum = impSum + value

                    } else {
                        let tasaEx = 100 - t.Tasa_de_extención
                        let valueA = (tasaEx * t.Tasa) / 100
                        let value = (orderInfo.SubTotal * valueA) / 100
                        proforma = {
                            ...proforma,
                            [t.WTCode]: value,
                            PorcentajeIBB:{
                                ...proforma.PorcentajeIBB,
                                [t.WTCode]: t.Tasa,
                                [`ext${t.WTCode}`]: tasaEx
                            },
                        }

                        impSum = impSum + value
                    }
                }
            })
        }
//console.log(proforma)
return {
    ...proforma,
    TotalConImp: orderInfo.SubTotal + impSum,
}
}
//console.log(orderInfo.proforma)

    // lo sacamos del return al setDisabledBtn por que en produccion no funcionaba
    useEffect(() => {
        setDisabledBtn(false)
        // return () => {
            
        // }
    }, [])

  
    return (
        <>
            {
                isOpenModal.a
                &&
                <NotificationModal closeSection={closeSection} />
            }

            {
                isOpenModal.b
                &&
                <CreateOrder section={'MODIFICAR'} closeSection={closeSection} />
            }

            <div className='d-flex flex-column gap-2'>
                {
                    <>
                        {(userData.Permiso === 'Vendedor'
                            && orderInfo.Estado === 'Pendiente')

                            &&

                            <button
                                name='Modificar'
                                className='btn  btnModificar btnOrderStatus w-100 mt-5 p-3'
                                disabled={disableButton()}
                                //disabled = {dis}
                                onClick={handleModifications}
                            >
                                Modificar
                            </button>
                        }
                        {
                            (
                                ['Pendiente', 'Autorizado'].includes(orderInfo.Estado)
                                && !['Vendedor', 'Administracion'].includes(userData.Permiso)
                            )
                            &&
                            <>
                                {/* MODIFICAR */}
                                <button
                                    name='Modificar'
                                    className='btn  btnModificar btnOrderStatus w-100 mt-5 p-3'
                                    disabled={disableButton() || disabledBtn}
                                    // disabled={dis || disabledBtn}
                                    onClick={handleModifications}
                                >
                                    Modificar
                                </button>

                                {/* RECHAZAR */}
                                <div className='d-flex gap-2'>
                                    <div className='w-100' >
                                        <details className={`btn btnReject textBlack clrBgDecline btnOrderStatus py-3 w-100 ${disableButton() || disabledBtn || autorizando ? 'disabledDet' : ''}`}>
                                        {/* <details className={`btn btnReject textBlack clrBgDecline btnOrderStatus py-3 w-100 ${dis || disabledBtn || autorizando ? 'disabledDet' : ''}`}>    */}
                                            <summary >Rechazar</summary>
                                            <NotificationModal closeSection={closeSection} disableButton={disableButton} disabledBtn={disabledBtn} autorizando={autorizando} />
                                        </details>
                                    </div>
                                    {
                                        userData.Permiso === 'Direccion'
                                            ?
                                            //SAP
                                            <button
                                                type='button'
                                                className={`btn btnSAP textBlack clrBgForward btnOrderStatus w-100 maxHeigth58 ${cargandoSap ? 'p-0' : 'p-3'}`}
                                                disabled={disableButton() && !sapConnected || disabledBtn || cargandoSap}
                                                // disabled={dis && !sapConnected || disabledBtn || cargandoSap}
                                                onClick={()=>handleOrderState("Cargado en SAP")}
                                                name="Cargado en SAP"
                                            >

                                                {
                                                    cargandoSap
                                                        ? <div className=' spinner-border'></div>
                                                        : <span>SAP</span>
                                                }
                                            </button>
                                            :
                                            //AUTORIZAR
                                            <button
                                                type='button'
                                                className={`btn btnAccept textBlack clrBgAccept btnOrderStatus maxHeigth58 p-3 w-100 ${autorizando ? 'p-0' : 'p-3'}`}
                                                disabled={disableButton()|| orderInfo.Estado === 'Autorizado' || disabledBtn || autorizando}
                                                // disabled={dis|| orderInfo.Estado === 'Autorizado' || disabledBtn || autorizando}
                                                onClick={()=>handleOrderState("Autorizado")}
                                                name="Autorizado"
                                            >
                                                {
                                                    autorizando
                                                        ? <div className='spinner-border'></div>
                                                        : <span> Autorizar</span>
                                                }
                                            </button>
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </div >
        </>
    )
}