import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camionIco from '../../assets/images/camionIco.png'
import { CardHead, Loading } from '../ui/index'
import { ExportOrder, OrderDetailButtons } from './index'
import { addOrderData, resetOrderData } from '../../store/orderData/OrderDataSlices';
import { useGeneral } from '../../context';
import { CreateOrder } from './createOrder';
import homeIco from '../../assets/images/homeIco.png';
import accesoIco from '../../assets/images/accesoIco.png';
import commentsIco from '../../assets/images/comentarioIco.png';
import warnIco from '../../assets/images/advertenciaIco.png';
import editadoIco from '../../assets/images/escribirIco.png'
import { TableTks } from '../tools/TableTks';

//-----------------------------------------//
//-----------------PROFORMA----------------//
//-----------------------------------------//

export const OrderDetail = ({ closeSection }) => {

    const topDiv = useRef(null)

    const { tiposPedidos, objectCompare, sumProducts } = useGeneral()

    const clientInfo = useSelector(state => state.clientInfo)
    const userData = useSelector((state) => state.user)
    const orderInfo = useSelector((state) => state.orderData)
    const fullOrd = useSelector((state) => state.orders)
    const isOpenModal = useSelector(state => state.openModal)
    const clients = useSelector(state => state.clients)
    const condicionesDePago = useSelector(state => state.condicionesDePago)
    const sapConnected = useSelector((state) => state.sapConnected)
    const downloading = useSelector((state) => state.downloadElements)
    //  console.log('test')
    //  console.log((orderInfo.Proforma.P013))
    //  console.log((orderInfo.Proforma.PorcentajeIBB.P013))
    const [data, setData] = useState([])
    //Abre el modal de exportacion
    const [exportModal, setExportModal] = useState(false)
    const [whithIva,setWithIva] = useState(false)
    

    useEffect(() => {
        const clientEnc = clients.find((e) => e.CUIT === orderInfo.Cuit);
    
        if (clientEnc && clientEnc.Iva === 'EX' && clientEnc.liquidaIva === 'S') {
            setWithIva(true);
                // console.log('siniv')
        } else {
            setWithIva(false);
                //  console.log('coniv')
        }
    }, [orderInfo, clients]);
    //---------------------------
    //neto
    //console.log(orderInfo)
    //total
    //console.log(orderInfo.sumTotalConIvaTripl)
    //---------------------------
    //console.log(clientEnc.liquidaIva)
    //console.log(clientEnc.Iva) 
    //---------------------------

    const cuentasBanca = [
        { bco: "CREDICOOP", cc: "174-007977/2", cbu: "1910174555017400797728" },
        { bco: "SANTANDER", cc: "000-0-024971/5", cbu: "0720000720000002497154" },
        { bco: "BBVA FRANCES", cc: "469-002197/6", cbu: "0170469720000000219761" },
        { bco: "NACION", cc: "7240028043", cbu: "0110724320072400280434" },
        { bco: "PROVINCIA", cc: "050493/3 SUC:4019", cbu: "0140019901401905049333" }
    ]

    const dispatch = useDispatch()

    //funcion para formatear a pesos arg (formatter.format(num))
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    //retorna la clase dependiendo el estado, se llama en el classlist del <div>
    const stateClass = () => {
        let cssClass = 'estadoCargado'

        //Cambiar el color de aprobado a naranja o amarillo... es un estado entre rechazado y autorizado
        if (orderInfo.Estado === 'Pendiente') {
            cssClass = 'estadoYellow'
        } else if (
            orderInfo.Estado === 'Rechazado'
            || orderInfo.Estado === 'Modificado'
        ) {
            cssClass = 'estadoRed'
        } else if (orderInfo.Estado === 'Autorizado') {
            cssClass = 'estadoGreen'
        }
        return cssClass
    }


    //console.log(orderInfo)


    const handleToggleOriginal = () => {
        if (orderInfo.RegistroOriginales[0]) {
            dispatch(addOrderData(orderInfo.RegistroOriginales[0]))
            setData(orderInfo.RegistroOriginales[0].DocumentLines)
        } else {

            let actualOrder = fullOrd.find((ord) => ord.Num_pedido === orderInfo.Num_pedido)
            dispatch(addOrderData(actualOrder))
            setData(actualOrder.DocumentLines)
        }

    }
    


    //agrega la info del pedido cuyo id sea igual a el url
    useEffect(() => {
        // dispatch(resetOrderData())    
        let stringUrl = window.location.toString()
        let ordNum = stringUrl.split('=').pop()

        dispatch(addOrderData(fullOrd.find(obj => obj.Num_pedido === ordNum)))

        //mueve el scroll hacia el inicio
        orderInfo.ID !== ''
            &&
            topDiv.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

        //limpia el pedido que este cargado en rdx
        // return () => {
        //     dispatch(resetOrderData())
        // }
    }, [])



    useEffect(() => {

        modifiedDocLines('MUP')
        return () => {
            dispatch(resetOrderData())
        }
        
    }, [])

    //da los valores viejos {}
    const compareModifications = (newOrder, originalOrder) => {
        // campos exluidos en la comparacion
        const excludeFields = [
            'Estado', 'RegistroOriginales', 'Modificador', 'Fecha',
            'VendedorApellido', 'VendedorNombre'
        ]
        const result = {};


        // Itera sobre las claves del objeto nuevo
        for (const key in newOrder) {
            if (newOrder.hasOwnProperty(key)) {
                // Verifica si la clave existe en el objeto anterior
                if (originalOrder.hasOwnProperty(key)) {
                    // Verifica si la clave está en la lista de campos excluidos
                    if (!excludeFields.includes(key)) {
                        // Compara los valores asociados a la clave
                        if (newOrder[key] !== originalOrder[key]) {
                            // Registra la clave y el valor del objeto nuevo si son diferentes
                            result[key] = originalOrder[key];
                        }
                    }
                }
            }
        }
        return result;
    }

    // FUNCIONES PARA CAMBIAR LA CLASE DE LAS MODIFICACIONES
    const modifiedValue = (field) => {
        // if (orderInfo.Modificador) {
        if (orderInfo.RegistroOriginales.length > 0) {
            const original = orderInfo.RegistroOriginales[orderInfo.RegistroOriginales.length - 1];
            const modif = compareModifications(orderInfo, original)


            if (field === 'PymntGroup' && modif['PymntGroup']) {
                if (!objectCompare(modif[field], orderInfo[field])) {
                    return 'modification'
                }
            } else if (
                modif[field] &&
                (modif[field] !== orderInfo[field])) {
                return 'modification'
            }
        }
    }
        
    //recibe el indice del producto y lo compara con el viejo marca la linea entera
    const modifiedDocLines = (index) => {
        if (orderInfo.RegistroOriginales.length > 0) {
            const originalDoclines = orderInfo.RegistroOriginales[orderInfo.RegistroOriginales.length - 1].DocumentLines

            if (
                (JSON.stringify(orderInfo[index]) !== JSON.stringify(originalDoclines[index]))
                || !originalDoclines[index]
            ) {
                return 'modification'
            }
        }
    }

    const modifiedDoclineValue = (index, field) => {
        // if (orderInfo.Modificador) {
        if (orderInfo.RegistroOriginales.length > 0) {
            const originalDoclines = orderInfo.RegistroOriginales[orderInfo.RegistroOriginales.length - 1].DocumentLines

            if (originalDoclines[index]) {
                if (orderInfo.DocumentLines[index][field] !== originalDoclines[index][field]) {

                    return 'modification'
                }
            } else {
                return 'modification'
            }
        }
    }
    /************************/

    ///////////////////////////
    //console.log(orderInfo.Proforma)
    return (
        (orderInfo.ID !== '')
            ?
            <div className="card" ref={topDiv}>
                <CardHead
                    section={'ORDEN'} orderInfo={orderInfo}
                    closeSection={closeSection} setExportModal={setExportModal}
                />
                {
                    exportModal
                    &&
                    <ExportOrder exportModal={exportModal} setExportModal={setExportModal}
                        cuentasBanca={cuentasBanca} 
                    />
                }

                {
                    isOpenModal.b &&
                    <CreateOrder orderInfo={orderInfo} closeSection={closeSection} />
                }

                {/* ENCABEZADO */}
                <div className={`d-flex flex-column pb-2 clrGr${orderInfo.Estado.split(' ')[0]}`}>

                    {/* ESTADO */}
                    <div className={`d-flex flex-column  ${stateClass()}`}>
                        <div
                            className='fs-2 w-100 text-center fw-bold textWhite px-3'
                        >
                            <span className={`fw-bold text-uppercase ordSh${orderInfo.Estado.split(' ')[0]}`}>
                                {orderInfo.Estado}
                            </span>
                            {userData.Permiso !== 'Vendedor' && orderInfo.DocNum && (
                                    <span>
                                        &nbsp; # {orderInfo.DocNum}
                                    </span>
                                )}
                        </div>
                        {
                            orderInfo.Proforma
                            &&
                            <div className='text-center'>
                                <span className='fw-bold textWhite'>
                                    Proforma:&nbsp;
                                    {orderInfo.Proforma.numProforma}
                                    &nbsp;({orderInfo.Proforma.fecha})
                                </span>
                            </div>
                        }
                    </div>

                    {/* INFO de pedido */}
                    <div
                        className='ordDetailInfo px-3 pt-2 text-center px05SM pt1SM fontSizeSmallSM'
                    >
                        <div className='d-flex mb-2'>
                            <div className='pe-3 d-flex flex-column minWFitCont'>
                                <span className=''>
                                    ID #:
                                </span>

                                <span >
                                    Pedido #:
                                </span>

                            </div>
                            <div className='d-flex flex-column text-end minWFitCont'>
                                <span className='fw-bold'>
                                    {orderInfo.Num_pedido}
                                </span>

                                <span
                                    className={`fw-bold ${modifiedValue('NumPedidoDelCliente')}`}
                                >
                                    {
                                        orderInfo.NumPedidoDelCliente
                                            ? orderInfo.NumPedidoDelCliente
                                            : '-'
                                    }
                                </span>
                            </div>

                            <div className='flex-grow-1 align-self-center'>
                                <label className='textSmall text-end'>Fecha</label>
                                <span className='textBlack text-end'>
                                    {
                                        orderInfo.Fecha
                                    }
                                </span>
                            </div>
                        </div>

                        <div className=' d-flex flex-wrap justify-content-between ordDetailRow'>

                            <div className='mx-3'>
                                <label className='textSmall'>Tipo</label>

                                <span className={`textBlack ${modifiedValue('TipoPedido')}`}>
                                    {
                                        tiposPedidos[orderInfo.TipoPedido - 1]
                                    }
                                </span>
                            </div>

                            <div className='mx-3'>
                                <label className='textSmall'>Pago</label>
                                <span className={`textBlack ${modifiedValue('PymntGroup')}`}>
                                    {orderInfo.PymntGroup.PymntGroup}
                                </span>
                            </div>

                            <div className='mx-3'>
                                <label className='textSmall'>Entregas</label>
                                <span className={`textBlack ${modifiedValue('CantEntregas')}`}>
                                    {orderInfo.CantEntregas}
                                </span>
                            </div>

                            <div className='mx-3'>
                                {
                                    orderInfo.MesSelecc
                                    &&
                                    <>
                                        <label className='textSmall'>Mes Pact.</label>
                                        <span className={`textBlack ${modifiedValue('MesSelecc')}`}>
                                            {orderInfo.MesSelecc}-{orderInfo.AnioSelecc} 
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>



                {/* SEGUIMIENTO */}
                <div className='px8SM'>
                    {
                        orderInfo.Modificador
                        &&
                        <div className='d-flex'>
                            <div className='modification d-flex align-items-center'>
                                <img src={editadoIco}
                                    className='icon32px m-auto align-self-start'
                                    title='Orden Modificada'
                                    alt="Ord_Modificada"
                                />
                                <h5 className=" m-0 px-2 fontSizeSmallSM widthFitCont" >
                                    Modificado por {orderInfo.Modificador.Apellido}, el {orderInfo.Modificador.fecha}
                                </h5>
                            </div>

                            <button
                                id='irPedidoBtn'
                                className='btn textShadow0 fontSizeSmallSM'
                                onClick={handleToggleOriginal}
                            >
                                {
                                    orderInfo.RegistroOriginales[0]
                                        ? 'ver Anterior'
                                        : 'ver Actual'
                                }

                                <img
                                    className="align-top icon20px ms-3 invert"
                                    src={accesoIco} alt="Ir al pedido"
                                />
                            </button>


                        </div>
                    }

                    {
                        orderInfo.SapResponse && orderInfo.Estado !== "Cargado en SAP"
                        &&

                        <div className='modification d-flex align-items-center'>
                            <img src={warnIco}
                                className='icon32px m-auto align-self-start'
                                title='Orden Modificada'
                                alt="Ord_Modificada"
                            />
                            <h5 className=" m-0 px-2 fontSizeSmallSM fw-bold widthFitCont" >
                                {orderInfo.SapResponse.Response}
                            </h5>
                        </div>


                    }
                    <div className='d-flex p-3 justify-content-between align-items-center'>
                        <div>
                            <h4 className='fw-bolder text-uppercase'>
                                Seguimiento
                            </h4>

                            <div className='mb-2'>
                               <b>&#128504;</b> 
                                {
                                    orderInfo.Turno !== 'Si'
                                        ? <span className='fw-bold'> SIN TURNO</span>
                                        : <span className={`${modifiedValue('MesSelecc')}`}>
                                            <b>CON TURNO:</b>  {orderInfo.Contacto_Turno}
                                        </span>
                                }
                            </div>
                        </div>

                        <div className=' text-end position-relative align-self-start'>
                            <label className='position-absolute labelTop'>
                                Vendedor
                            </label>

                            <span className='fw-bold'>
                                {orderInfo.VendedorApellido}
                            </span>

                        </div>
                    </div>



                    <div className='row borderB border-top py-3'>
                        {/* COL 1 */}

                        {/* COL 2 */}
                        <div className='col-md-6 col-12 pe-2 d-flex flex-column '>

                            <div className='d-flex mb-3 align-items-center'>
                                <img src={camionIco} alt="img_camion" className='icon40px' />
                                <span className='fw-bold ms-3 fontSize17 text-uppercase'>
                                    ENVÍO
                                </span>
                            </div>

                            <span >
                                <img src={homeIco} className="hw18px me-2" alt='dir_entr' />

                                {
                                    orderInfo.Direccion_Entrega_New
                                        ?
                                        orderInfo.Direccion_Entrega_New.ShipToStreet + ' ' +
                                        orderInfo.Direccion_Entrega_New.ShipToStreetNo + ' - ' +
                                        orderInfo.Direccion_Entrega_New.ShipToCity +
                                        ' (' + orderInfo.Direccion_Entrega_New.ShipToZipCode + ') ' +
                                        orderInfo.Direccion_Entrega_New.ShipToCountry

                                        :
                                        orderInfo.Direccion_Entrega + ' - ' + orderInfo.Provincia_Entrega
                                }
                            </span>

                            <span>
                                <span className="textColorGreyL me-2">&#9992;</span>
                                {orderInfo.Transporte}
                            </span>
                            <span>
                                <span className="textColorGreyL me-2">&#9990;</span>

                                {orderInfo.TelTransporte}
                            </span>
                        </div>
                        {/* COL 3 */}
                        <div className='commentsDetSM ps-2 col-md-6 col-12 d-flex flex-column border-start'>
                            <div className='d-flex mb-3 align-items-center'>
                                <img src={commentsIco} alt='img_comentarios' className='icon40px ' />
                                <span className='fw-bold fontSize17 ms-3 text-uppercase '>
                                    Comentarios
                                </span>
                            </div>
                            <span className={`${modifiedValue('MesSelecc')}`}>
                                {orderInfo.Comments}
                            </span>
                        </div>
                    </div>
                </div>

                {/* TABLA DE PRODUCTOS */}
                <div>
                    {!isOpenModal.b && (
                        orderInfo.DocumentLines ? (
                        <TableTks
                            section={'ORDEN'}
                            data={data}
                            modifiedDoclineValue={modifiedDoclineValue}
                        />
                        ) : (
                        <Loading />
                        )
                    )}
                </div>

                {
                    orderInfo.Proforma
                    &&
                    <p className='my-2 px-3 small fontSizeSmallSM'>
                        <span className='textRed fw-bold'>Vigencia de la proforma:</span> 72hs hábiles. La presente no compromete stock ni reserva unidades,
                        puede variar según disponibilidad de la empresa.
                    </p>
                }

                {/* DETALLE */}
                <div className='row mt-4 border-top g-0'>

                    {/* BANCARIOS */}
                    <div className='detailBox col-lg-6 col-md-12 p-4 px8SM order-2 orderlg-1'>
                        <div className='pb-4 pb0LG'>
                            <h4 className='fw-bolder'>CUENTAS PARA DEPOSITAR</h4>

                            <p>Cuenta corriente en pesos a nombre de <strong>GOLDMUND S.A. </strong><br />
                                CUIT: <b>30-70860387-9</b>
                            </p>
                        </div>

                        <div className='cbuBox'>
                            {
                                cuentasBanca.map((banco) => (
                                    <div key={banco.bco} className="d-flex flex-wrap  fontSizeSmallSM borderB">
                                        <span className='fw-bold widthBancos'>
                                            {banco.bco}
                                        </span>
                                        <div className='ms-auto widthCuentaCorr text-end pe-2'>
                                            <span className='fontSize13 me-2  fw-bold'>
                                                C/C</span>
                                            <span>
                                                {banco.cc}
                                            </span>
                                        </div>
                                        <div className='ms-auto pe-2 ps-3'>
                                            <span className='fontSize13 me-2 fw-bold'>
                                                CBU</span>
                                            <span >
                                                {banco.cbu}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>

                    </div>

                    {/* DETALLE */}
                    <div className='p-4 clrBgLightGray col-lg-6 col-md-12 px8SM order-1 order-lg-2'>

                        <div className='detailBox maxW460 m-auto'>

                            <h4 className='pb-3 fw-bolder'>DETALLE</h4>

                            <div className='d-flex flex-column'>
                                <div className='d-flex flex-wrap fontSize18 fs-5 justify-content-between pb-2 hoverSwipe'>
                                    <span className='fw-bold'>NETO</span>
                                    <span className='fw-bold bigger ps-3 ms-auto'>
                                       {
                                        orderInfo.sumCostoConIvaTripl &&
                                        whithIva
                                        ?formatter.format(orderInfo.sumCostoConIvaTripl)
                                        :formatter.format(orderInfo.Neto)
                                        } 
                                    </span>
                                </div>

                                <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                    <span className={`${modifiedValue('Descuento02')}`}>DC. &nbsp; {orderInfo.Descuento02} %</span>
                                    <span className=' bigger ps-3 ms-auto'>$ {(orderInfo.Neto * (orderInfo.Descuento02 / 100)).toFixed(2)} </span>

                                </div>
                                <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                    <span className={`${modifiedValue('Descuento03')}`}>DF. &nbsp; {orderInfo.Descuento03} %</span>
                                    <span className='bigger ps-3 ms-auto'>$ {(orderInfo.Neto * (orderInfo.Descuento03 / 100)).toFixed(2)}</span>

                                </div>
                                <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                    <span className={`${modifiedValue('Descuento03')}`}>DPP. &nbsp; {orderInfo.Descuento01} %</span>
                                    <span className='bigger ps-3 ms-auto'>$ {(orderInfo.Neto * (orderInfo.Descuento01 / 100)).toFixed(2)}</span>

                                </div>

                                <div className='d-flex flex-wrap fontSize18 justify-content-between pb-2 hoverSwipe'>
                                    <span className='fw-bold' >SUBTOTAL</span>
                                    <span className='fw-bold bigger ps-3 ms-auto'>  
                                         {
                                            orderInfo.SubTotal &&
                                            whithIva 
                                            ?formatter.format(orderInfo.sumTotalConIvaTripl)
                                            :formatter.format(orderInfo.SubTotal)
                                        } 
                                 </span>
                                </div>

                                <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                    <span className=''>IVA 21 %</span>
                                    <span className='bigger ps-3 ms-auto'>
                                        {formatter.format(orderInfo.IVA21)}
                                    </span>
                                </div>
                                {/* <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                    <span className=''>Bono Total </span>
                                    <span className='bigger ps-3 ms-auto'>
                                            {formatter.format(orderInfo.TotalBono || 0)} 
                                    </span>
                                </div> */}

                                {
                                    orderInfo.Proforma.P007 > 0
                                    &&
                                    <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                        <span className=''>IIBB CABA %</span>

                                            <span className='ps-3 ms-auto'>
                                               {
                                                `${((orderInfo.Proforma.P007 / orderInfo.Total) * 100).toFixed(2)}%`
                                                } 
                                            </span> 
                                            <span className='ps-3 ms-auto'>
                                                
                                                {
                                                orderInfo.Proforma.PorcentajeIBB?.P007 
                                                    ? `Tasa:${orderInfo.Proforma.PorcentajeIBB.P007.toFixed(2)}%` 
                                                    : ''
                                                }
                                            </span>
                                            <span className='bigger ps-3 ms-auto'>
                                                {
                                                    formatter.format(orderInfo.Proforma.P007)
                                                }
                                            </span>
                                    </div>
                                }
                                {
                                    orderInfo.Proforma.P013 > 0
                                    &&
                                    <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                        <span className=''>IIBB BS AS</span>
                                            <span className='ps-3 ms-auto'>
                                                {
                                                    `${((orderInfo.Proforma.P013 / orderInfo.Total) * 100).toFixed(2)}%`
                                                } 
                                            </span>
                                            <span className='ps-3 ms-auto'>
                                                 
                                                     {
                                                    orderInfo.Proforma.PorcentajeIBB?.P013 
                                                        ? `Tasa: ${(orderInfo.Proforma.PorcentajeIBB.P013 * 100).toFixed(2)}%` 
                                                        : ''
                                                    } 
                                            </span>
                                            <span className='bigger ps-3 ms-auto'>
                                                {
                                                    formatter.format(orderInfo.Proforma.P013)
                                                }
                                            </span>
                                    </div>
                                }
                                {
                                    orderInfo.Proforma.PC01 > 0
                                    &&
                                    <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                        <span className=''>IIBB TUC %</span>
                                 
                                           <span className='ps-3 ms-auto'>
                                                {
                                                `${((orderInfo.Proforma.PC01 / orderInfo.Total) * 100).toFixed(2)}%`
                                            } 
                                            </span> 
                                            <span className='ps-3 ms-auto'>
                                                
                                                {
                                                orderInfo.Proforma.PorcentajeIBB?.PC02 
                                                    ? `Tasa: ${orderInfo.Proforma.PorcentajeIBB.PC02.toFixed(2)}%` 
                                                    : ''
                                                }
                                              
                                                {
                                                orderInfo.Proforma.PorcentajeIBB?.extPC02 
                                                    ? `Ext:  ${orderInfo.Proforma.PorcentajeIBB.extPC02.toFixed(2)}` 
                                                    : ''
                                                }        
                                            </span>
                                            <span className='bigger ps-3 ms-auto'>
                                                {
                                                    formatter.format(orderInfo.Proforma.PC01)
                                                }
                                            </span>
                                    </div>
                                }
                                {
                                    orderInfo.Proforma.PC02 > 0
                                    &&
                                    <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                        <span className=''>IIBB CORD %</span>
                                        <span className='ps-3 ms-auto'>
                                            {
                                                `${((orderInfo.Proforma.PC02 / orderInfo.Total) * 100).toFixed(2)}%`
                                            } 
                                        </span> 
                                        <span className='ps-3 ms-auto'>
                                                
                                                        {
                                                        orderInfo.Proforma.PorcentajeIBB?.PC02  
                                                            ? `tasa: ${orderInfo.Proforma.PorcentajeIBB.PC02.toFixed(2)}%` 
                                                            : ''
                                                        }
                                        </span>
                                        <span className='bigger ps-3 ms-auto'>
                                            {
                                                formatter.format(orderInfo.Proforma.PC02)
                                            }
                                        </span>
                                    </div>
                                }
                                {
                                    orderInfo.Proforma.P016 > 0
                                    &&
                                    <div className='d-flex flex-wrap justify-content-between pb-2 hoverSwipe'>
                                        <span className=''>IIBB ER</span>

                                        <span className='bigger ps-3 ms-auto'>
                                            {
                                                formatter.format(orderInfo.Proforma.P016)
                                            }
                                        </span>
                                       <span className='ps-3 ms-auto'>
                                               {
                                                `${((orderInfo.Proforma.P016 / orderInfo.Total) * 100).toFixed(2)}%`
                                                } 
                                        </span> 

                                    </div>
                                }

                                <div className='d-flex flex-wrap fontSize18 border-top mt-2 pt-2 hoverSwipe'>
                                    <span className='fw-bold'>Unidades</span>
                                    <span className='fw-bold fontSize18 bigger ps-3 ms-auto'>
                                        {sumProducts(orderInfo.DocumentLines)}
                                    </span>
                                </div>

                                <div
                                    className={`d-flex flex-wrap mt-2 pt-2 hoverSwipe`}
                                >
                                    <span className={`fw-bold fontSize17rem ${modifiedValue('Total')}`}>
                                        TOTAL
                                    </span>
                                    <span className='fw-bold fontSize17rem xxBigger ps-3 ms-auto'>
                                        {
                                            orderInfo.Proforma.TotalConImp
                                                ?
                                                formatter.format(orderInfo.Proforma.TotalConImp)
                                                :(
                                                    orderInfo.Total &&
                                                    whithIva
                                                    ?formatter.format(orderInfo.sumTotalConIvaTripl)
                                                    :formatter.format(orderInfo.Total)
                                                )
                                        }
                                    </span>
                                </div>


                                {
                                    Object.values(downloading).includes(false)
                                        ?
                                        <div className='m-auto'>
                                            <span className="loaderIndi"></span>
                                        </div>
                                        :
                                        <OrderDetailButtons
                                            //ordState={ordState}
                                            // setOrdState={setOrdState}
                                            orderInfo={orderInfo}
                                            closeSection={closeSection}
                                        />
                                }





                            </div>
                        </div>
                    </div>

                    <p className='my-4 px-3 small fontSizeSmallSM order-3'>
                        <b>NOTA:</b> El valor expresado en la nota de pedido es con IVA.
                        GOLDMUND S.A es agente de percepcion de ingresos brutos en la prov. de Bs.As y C.A.B.A,
                        Por lo que se incluira la alicuota correspondiente en cada factura, la cual debera ser abonada al momento de emision.
                        <br /><br />
                        <b>*</b> LAS CONDICIONES PRESENTES PUEDEN CAMBIAR ANTE FLUCTUACIONES DE CONTEXTO.
                        LISTA DE PRECIOS SUJETA A MODIFICACION SIN PREVIO AVISO Y A EVALUACION COMERCIAL <b>*</b>
                    </p>
                </div>
            </div >
            :
            <Loading />
    )
}
